import React, { useState, useRef, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { MainContext } from "../../../context/MainContext";
import Icon from "react-crypto-icons";
import { Col, Card } from "react-bootstrap";

class ExchangeHistory extends React.Component {
  static contextType = MainContext

  constructor(props) {
    super(props)

      this.state = {
        result: [],
        activePage: 1,
        itemsPerPage: 12,
        paginationSteps: 2,
        sortField: "Closed",
        sortOrder: "desc"
      }
  }

  componentDidMount() {
    fetch(global.url + 'index.php?route=app/app/exchange').then(res => res.json())
      .then(
        (result) => {
            if(!result.user || result.user == null || !result.user.id || result.user.id == 0) {
              this.context.logOutUser();
              this.props.history.push("/login");
              return;
            }            
            this.setState({
              result: result.data
            })
        },
        (error) => {
            console.log("Ophalen mislukt! ", error);
        }
      ).then(window.scrollTo(0, 0));
  }

  render() {
    if (this.state.result == null || this.state.result.length === 0) {
      return (
        <div className="row">
          <Col xl={12} lg={12}>
            <Card>
              <Card.Header>
                <Card.Title>Loading</Card.Title>
              </Card.Header>
              <Card.Body>
                Data is being loaded from server, please wait...
              </Card.Body>
            </Card>
          </Col>
        </div>
      )
    }

    const originalData = this.state.result.orders || [],
          data = originalData.map(object => ({ ...object })),
          totalPages = Math.ceil(data.length / this.state.itemsPerPage);

    data.sort((a, b) => {
      let firstValue = this.state.sortOrder === "desc" ? a[this.state.sortField] : b[this.state.sortField];
      let secondValue = this.state.sortOrder === "desc" ? b[this.state.sortField] : a[this.state.sortField];

      if(/(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/gm.test(firstValue)) {
        let parts_a = firstValue.split(/\D/),
            parts_b = secondValue.split(/\D/);
        return new Date(parts_b[0], --parts_b[1], parts_b[2], parts_b[3], parts_b[4], parts_b[5]) - new Date(parts_a[0], --parts_a[1], parts_a[2], parts_a[3], parts_a[4], parts_a[5]);
      } else if(!isNaN(firstValue)) {
        return secondValue - firstValue;
      } else {
        if (secondValue < firstValue) return -1;
        if (secondValue > firstValue) return 1;
        return 0;
      }
    })

    let pagination = [];
    const addPage = (s, f) => {
      for (let i = s; i < f; i++) {
        pagination.push( 
          <Link
          key={i}
          to="/exchange"
          className={`paginate_button ${
            this.state.activePage === i ? "current" : ""
          } `}
          onClick={() => this.setState({ activePage: i })}
          style={{ display: "inline-block" }}
        >
          {i}
        </Link>);
      }
    };

    const firstPage = () => {
      pagination.push(
          <span>
            <Link
            key="1"
            to="/exchange"
            className={`paginate_button ${
              this.state.activePage === 1 ? "current" : ""
            } `}
            onClick={() => this.setState({ activePage: 1 })}
            style={{ display: "inline-block" }}
          >
            1
          </Link>
          <span className={`paginate_button disabled`} style={{ display: "inline-block" }}>...</span>
        </span>);
    }; 

    const lastPage = () => {
      pagination.push(
          <span>
            <span className={`paginate_button disabled`} style={{ display: "inline-block" }}>...</span>
            <Link
              key={totalPages}
              to="/exchange"
              className={`paginate_button ${
                this.state.activePage === totalPages ? "current" : ""
              } `}
              onClick={() => this.setState({ activePage: totalPages })}
              style={{ display: "inline-block" }}
            >
              {totalPages}
            </Link>
          </span>
          );
    }; 

    if (totalPages < this.state.paginationSteps * 2 + 6) {
      addPage(1, totalPages + 1);
    } else if (this.state.activePage < this.state.paginationSteps * 2 + 1) {
      addPage(1, this.state.paginationSteps * 2 + 4);
      lastPage();
    } else if (this.state.activePage > totalPages - this.state.paginationSteps * 2) {
      firstPage();
      addPage(totalPages - this.state.paginationSteps * 2 - 2, totalPages + 1);
    } else {
      firstPage();
      addPage(this.state.activePage - this.state.paginationSteps, this.state.activePage + this.state.paginationSteps + 1);
      lastPage();
    }

    return (
        <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive table-hover fs-14">
            <div id="example5_wrapper" className="dataTables_wrapper no-footer">
            <table className="table display mb-4 dataTablesCard border-no card-table text-black dataTable no-footer" id="transaction_table">
            <thead>
              <tr role="row" className="font-w600">
                <th style={{cursor: "pointer", paddingTop: '10px', paddingBottom: '10px'}} onClick={() => this.setState({ sortField: "Closed", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} rowSpan={1} colSpan={2}>
                  Date {this.state.sortField === "Closed" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                </th>
                <th style={{cursor: "pointer", padding: "10px 0"}} onClick={() => this.setState({ sortField: "Currency", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} rowSpan={1} colSpan={1}>
                  Asset {this.state.sortField === "Currency" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                </th>
                <th style={{cursor: "pointer", padding: "10px 0"}} onClick={() => this.setState({ sortField: "OrderType", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} rowSpan={1} colSpan={1}>
                  Order Type {this.state.sortField === "OrderType" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                </th>
                <th style={{cursor: "pointer", padding: "10px 0"}} onClick={() => this.setState({ sortField: "Quantity", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} rowSpan={1} colSpan={1}>
                  Coins {this.state.sortField === "Quantity" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                </th>
                <th style={{cursor: "pointer", padding: "10px 0"}} onClick={() => this.setState({ sortField: "PricePerUnit", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} rowSpan={1} colSpan={1}>
                  Price {this.state.sortField === "PricePerUnit" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                </th>
                <th style={{cursor: "pointer", padding: "10px 0"}} onClick={() => this.setState({ sortField: "Price", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} rowSpan={1} colSpan={1}>
                  Total {this.state.sortField === "Price" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                </th>
              </tr>
            </thead>
              <tbody>
                {data.slice((this.state.activePage - 1) * this.state.itemsPerPage, (this.state.activePage) * this.state.itemsPerPage).map((order) => {
                  return (
                      <tr role="row">
                          <td style={{width: 30, paddingTop: "8px", paddingBottom: "8px"}}>
                            <span className="activity-icon" style={{width: 32, height: 32, lineHeight: "24px", border: "2px solid #EEEEEE"}}>
                              {order.OrderType.toLowerCase().includes('buy') ?
                              <svg
                                width={10}
                                viewBox="0 0 15 27"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.81299 21.393L8.81299 2.74998C8.81299 1.95606 8.16942 1.31248 7.37549 1.31248C6.58157 1.31248 5.93799 1.95606 5.93799 2.74998L5.93799 21.393L3.5194 18.9627L3.51938 18.9627C2.95934 18.4 2.0492 18.3978 1.48649 18.9578C0.923597 19.518 0.921712 20.4282 1.48158 20.9908L1.83599 20.6381L1.48158 20.9908L6.35659 25.8894L6.36177 25.8946L6.36345 25.8962C6.92422 26.4522 7.82863 26.4503 8.38748 25.8962L8.38912 25.8947L8.3944 25.8894L13.2694 20.9907L13.2694 20.9907C13.8294 20.428 13.8273 19.5179 13.2645 18.9578C12.7018 18.3977 11.7917 18.3999 11.2316 18.9626L11.2316 18.9626L8.81299 21.393Z"
                                  fill="#FF0000"
                                  stroke="#FF0000"
                                />
                              </svg> 
                              : 
                              <svg
                                width={10}
                                viewBox="0 0 15 27"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.9375 6.232L5.9375 24.875C5.9375 25.6689 6.58107 26.3125 7.375 26.3125C8.16892 26.3125 8.8125 25.6689 8.8125 24.875L8.8125 6.23202L11.2311 8.66232L11.2311 8.66234C11.7911 9.22504 12.7013 9.2272 13.264 8.66718C13.8269 8.10702 13.8288 7.19681 13.2689 6.63421L12.9145 6.98691L13.2689 6.63421L8.3939 1.73558L8.38872 1.73037L8.38704 1.72878C7.82626 1.17281 6.92186 1.17469 6.36301 1.72877L6.36136 1.73033L6.35609 1.73563L1.48109 6.63426L1.48108 6.63426C0.921124 7.19695 0.9232 8.10709 1.48597 8.6672C2.04868 9.22725 2.95884 9.22509 3.51889 8.66239L3.51891 8.66236L5.9375 6.232Z"
                                  fill="#61C277"
                                  stroke="#61C277"
                                />
                              </svg>
                              }
                            </span>
                          </td>
                          <td style={{padding: "10px 0"}}>{order.Closed}</td>
                          <td style={{padding: "10px 0"}}>
                            <Link to={`/portfolio#${order.Currency}`}>
                              <Icon name={order.Currency.toLowerCase()} size={20} />
                              <span className="text-black ml-2">{order.Currency}</span>
                            </Link>
                          </td>
                          <td style={{padding: "10px 0"}} className={order.OrderType.toLowerCase().includes('buy') ? 'text-danger' : 'text-success'}>
                            {order.OrderType}
                          </td>
                          <td style={{padding: "10px 0"}}>{this.context.numberFormat(parseFloat(order.Quantity), (order.Quantity < 1 ? 5 : 2))}</td>
                          <td style={{color: "#50af95", padding: "10px 0"}}><Icon name="usdt" size={20} /> {this.context.numberFormat(parseFloat(order.PricePerUnit), (order.PricePerUnit < 1 ? 5 : 2))}</td>
                          <td style={{color: "#50af95", padding: "10px 0"}}><Icon name="usdt" size={20} /> {this.context.numberFormat(parseFloat(order.Price), (order.Price < 1 ? 5 : 2))}</td>
                      </tr>
                  )
              })}
              </tbody>
            </table>
            <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                <div className="dataTables_info">
                  Showing {(this.state.activePage - 1) * this.state.itemsPerPage + 1} to{" "}
                  {originalData.length > (this.state.activePage + 1) * this.state.itemsPerPage
                    ? (this.state.activePage) * this.state.itemsPerPage
                    : originalData.length}{" "}
                  of {originalData.length} entries
                </div>
                <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="example5_paginate"
                >
                  <Link
                    className="paginate_button previous disabled"
                    to="/exchange"
                    onClick={() =>
                      this.state.activePage > 1 && this.setState({ activePage: this.state.activePage - 1 })
                    }
                  >
                    <i className="fa fa-arrow-left" />
                  </Link>
                  {pagination.map((page, i) => <span key={i}> {page} </span>)}
                  <Link
                    className="paginate_button next"
                    to="/exchange"
                    onClick={() =>
                      this.state.activePage < totalPages &&
                      this.setState({ activePage: this.state.activePage + 1 })
                    }
                  >
                    <i className="fa fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(ExchangeHistory);