import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { MainContext } from "../../../context/MainContext";

import { ReactComponent as BinanceIcon } from "../../../images/exchanges/binance-logo.svg";
import { ReactComponent as BittrexIcon } from "../../../images/exchanges/bittrex-logo.svg";
import { ReactComponent as KucoinIcon } from "../../../images/exchanges/kucoin-logo.svg";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Image
import { Dropdown } from "react-bootstrap";

class Header extends React.Component {
  static contextType = MainContext

  logOut = () => {
    this.context.logOutUser();
    this.props.history.push("/login");
  }

  setExchange = (exchange) => {
    this.context.setExchange(exchange);
    
    const current = this.props.location.pathname;
    this.props.history.replace(`/reload`);
    setTimeout(() => {
      this.props.history.replace(current);
    });
  }

  render() {
    var path = window.location.pathname.split("/");
    var hashName = window.location.hash;
    var name = path[path.length - 1].replace('tpos', 'Trade Profit Orders').replace('autotrades', 'Auto Trades').replace('exchange', 'Exchange History').split("-");
    var filterName = (name.length >= 3 ? name.filter((n, i) => i > 0) : name)
    var finalName = filterName.includes("app")
      ? filterName.filter((f) => f !== "app")
      : filterName.includes("ui")
      ? filterName.filter((f) => f !== "ui")
      : filterName.includes("uc")
      ? filterName.filter((f) => f !== "uc")
      : filterName.includes("basic")
      ? filterName.filter((f) => f !== "basic")
      : filterName.includes("jquery")
      ? filterName.filter((f) => f !== "jquery")
      : filterName.includes("table")
      ? filterName.filter((f) => f !== "table")
      : filterName.includes("page")
      ? filterName.filter((f) => f !== "page")
      : filterName.includes("email")
      ? filterName.filter((f) => f !== "email")
      : filterName.includes("ecom")
      ? filterName.filter((f) => f !== "ecom")
      : filterName.includes("chart")
      ? filterName.filter((f) => f !== "chart")
      : filterName.includes("editor")
      ? filterName.filter((f) => f !== "editor")
      : filterName;

    return (
      <div className="header">
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-between">
              <div className="header-left">
                <div
                  className="dashboard_bar"
                  style={{ textTransform: "capitalize" }}
                >
                  {finalName.join(" ").length === 0
                    ? "Dashboard"
                    : finalName.join(" ") === "dashboard dark"
                    ? "Dashboard"
                    : finalName.join(" ") + (hashName !== '' ? ' - ' + hashName.replace('#', '') : '')}
                </div>
              </div>

              <ul className="navbar-nav header-right">
                {/*
                <Dropdown
                  as="li"
                  className="nav-item dropdown notification_dropdown "
                >
                  <Dropdown.Toggle
                    variant=""
                    as="a"
                    className="nav-link  ai-icon i-false c-pointer"
                    // href="#"
                    role="button"
                    data-toggle="dropdown"
                  >
                    <i className="flaticon-381-ring" />
                    <div className="pulse-css" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu align="right" className="mt-2">
                    <PerfectScrollbar className="widget-media dz-scroll p-3 ps">
                      <ul className="timeline">
                        <li>
                          <div className="timeline-panel">
                            <div className="media mr-2 media-success">
                              <i className="fa fa-dollar" />
                            </div>
                            <div className="media-body">
                              <h6 className="mb-1">Buy order: BTC @ 40.000</h6>
                              <small className="d-block">
                                23-04-2022 17:00
                              </small>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
                        <div
                          className="ps__thumb-x"
                          tabIndex={0}
                          style={{ left: 0, width: 0 }}
                        />
                      </div>
                      <div className="ps__rail-y" style={{ top: 0, right: 0 }}>
                        <div
                          className="ps__thumb-y"
                          tabIndex={0}
                          style={{ top: 0, height: 0 }}
                        />
                      </div>
                    </PerfectScrollbar>
                    <Link className="all-notification" to="/exchange">
                      View all orders without TPO <i className="ti-arrow-right" />
                    </Link>
                  </Dropdown.Menu>
                </Dropdown>
                */}

                {this.context.user != null &&
                <Dropdown as="li" className="nav-item dropdown">
                  <Dropdown.Toggle variant="info">
                    {this.context.user.exchange === "1" && <BittrexIcon className="fillWhite" height={24} />}
                    {this.context.user.exchange === "2" && <BinanceIcon className="fillWhite" height={24} />}
                    {this.context.user.exchange === "3" && <KucoinIcon className="fillWhite" height={24} />}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item active={this.context.user.exchange === "1"} onClick={() => this.setExchange(1)}>
                      <BittrexIcon />
                    </Dropdown.Item>
                    <Dropdown.Item active={this.context.user.exchange === "2"} onClick={() => this.setExchange(2)}>
                      <BinanceIcon fill='#F0B90B' />
                    </Dropdown.Item>
                    <Dropdown.Item active={this.context.user.exchange === "3"} onClick={() => this.setExchange(3)}>
                      <KucoinIcon />
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                }
                <Dropdown as="li" className="nav-item dropdown header-profile">
                  <Dropdown.Toggle
                    variant=""
                    as="a"
                    className="nav-link i-false c-pointer"
                    // href="#"
                    role="button"
                    data-toggle="dropdown"
                  >
                    {this.context.user != null &&
                      <img src={"https://ui-avatars.com/api/?background=random&name=" + this.context.user.voornaam + "+" + this.context.user.achternaam} width={20} alt="" />
                    }
                    {this.context.user != null &&
                      <div className="header-info">
                        <span>{this.context.user.voornaam}</span>
                        <small>{this.context.user.achternaam}</small>
                      </div>
                    }
                  </Dropdown.Toggle>

                  <Dropdown.Menu align="right" className="mt-2">
                    <Link className="dropdown-item ai-icon" to="/login" onClick={this.logOut.bind(this)}>
                      <svg
                        id="icon-logout"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-danger"
                        width={18}
                        height={18}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                        <polyline points="16 17 21 12 16 7" />
                        <line x1={21} y1={12} x2={9} y2={12} />
                      </svg>
                      <span className="ml-2">Logout </span>
                    </Link>
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    )
  }
}

export default withRouter(Header);