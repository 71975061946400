import React from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { MainContext } from "../../context/MainContext";

import logo from "../../images/cryptobyte_logo.svg";
import { default as emojis } from "../../emojis"

class Login extends React.Component {
  static contextType = MainContext

  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this);
    
    this.state = {
      result: ""
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    
    fetch(global.url + 'index.php?route=app/app/login', {
      method: 'POST',
      body: data,
    }).then(res => res.json())
    .then(
      (result) => {
          if(result.result === 'success') {
            this.context.setUser(result.user);
            this.props.history.push("/");
          } else {
              this.setState({
                result: result.result
            })
          }
      },
      (error) => {
          this.setState({
            result: "failed"
          })
      }
    ).then(window.scrollTo(0, 0));
  }

  render() {
    return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                        <img style={{maxWidth: "100%"}} src={logo} alt="" />
                    </div>
                    {this.state.result === "failed" &&
                      <Alert
                        variant="danger"
                        className="fade show"
                      >
                        {emojis.error}
                        <strong>Failed!</strong> Check your password and try again.
                      </Alert>
                    }
                    <form onSubmit={this.handleSubmit}>
                      <div className="form-group">
                        <label className="mb-1 ">
                          <strong>Username</strong>
                        </label>
                        <input
                          type="text"
                          name="username"
                          className="form-control"
                          defaultValue=""
                          placeholder="Enter your username..."
                        />
                      </div>
                      <div className="form-group">
                        <label className="mb-1 ">
                          <strong>Password</strong>
                        </label>
                        <input
                          type="password"
                          name="password"
                          className="form-control"
                          defaultValue=""
                          placeholder="Enter your password..."
                        />
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          Sign in
                        </button>
                      </div>
                    </form>
                    <div className="new-account mt-3">
                      <p className="text-center text-small">
                        <small>
                          <Link className="text-primary" to="forgot-password">
                          Forgot your password?
                          </Link>
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
}

export default withRouter(Login);