import React, { useState, useRef, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { MainContext } from "../../../context/MainContext";
import Icon from "react-crypto-icons";
import { Col, Card } from "react-bootstrap";

class TradeProfitOrders extends React.Component {
  static contextType = MainContext

  constructor(props) {
    super(props)

      this.state = {
        result: [],
        activePage: 1,
        itemsPerPage: 12,
        paginationSteps: 2,
        sortField: "TSstart",
        sortOrder: "desc"
      }
  }

  componentDidMount() {
    fetch(global.url + 'index.php?route=app/app/tpos').then(res => res.json())
      .then(
        (result) => {
          if(!result.user || result.user == null || !result.user.id || result.user.id == 0) {
            this.context.logOutUser();
            this.props.history.push("/login");
            return;
          }
            this.setState({
              result: result.data
            })
        },
        (error) => {
            console.log("Ophalen mislukt! ", error);
        }
      ).then(window.scrollTo(0, 0));
  }

  getMarket(marketID) {
    let originalArray = this.state.result.markets || [],
        markets = Object.keys(originalArray).map(keyName => ({ ...originalArray[keyName] }));

    for (var i = 0; i < markets.length; i++) {
      if(markets[i].MarketID === marketID) {
        return markets[i];
      }
    }

    return null;
  }

  render() {
    if (this.state.result == null || this.state.result.length === 0) {
      return (
        <div className="row">
          <Col xl={12} lg={12}>
            <Card>
              <Card.Header>
                <Card.Title>Loading</Card.Title>
              </Card.Header>
              <Card.Body>
                Data is being loaded from server, please wait...
              </Card.Body>
            </Card>
          </Col>
        </div>
      )
    }

    const originalData = this.state.result.profitOrders || [],
          data = Object.keys(originalData).map(keyName => ({ ...originalData[keyName] })),
          totalPages = Math.ceil(data.length / this.state.itemsPerPage),
          totalLength = data.length;

    data.sort((a, b) => {
      let firstValue = this.state.sortOrder === "desc" ? a : b;
      let secondValue = this.state.sortOrder === "desc" ? b : a;

      switch(this.state.sortField) {
        case "Ratio":
        case "Value":
        case "Next":
          let marketFirst = this.getMarket(firstValue.MarketID);
          let marketFirstBid = (marketFirst !== null) ? marketFirst.Bid : 0;
          let marketSecond = this.getMarket(secondValue.MarketID);
          let marketSecondBid = (marketSecond !== null) ? marketSecond.Bid : 0;

          if(this.state.sortField === "Ratio") {
            firstValue = ((parseFloat(marketFirstBid) * 100) / parseFloat(firstValue.PricePerUnit) - 100).toFixed(2);
            secondValue = ((parseFloat(marketSecondBid) * 100) / parseFloat(secondValue.PricePerUnit) - 100).toFixed(2);
          } else if(this.state.sortField === "Value") {
            firstValue = (parseFloat(parseFloat(firstValue.Quantity) - parseFloat(firstValue.SoldAmount)) * parseFloat(marketFirstBid));
            secondValue = (parseFloat(parseFloat(secondValue.Quantity) - parseFloat(secondValue.SoldAmount)) * parseFloat(marketSecondBid));
          } else {
            let quantityLeftFirst = (parseFloat(firstValue.Quantity) - parseFloat(firstValue.SoldAmount));
            let priceFirst = (parseFloat(firstValue.PricePerUnit) * parseFloat(firstValue.Quantity));
            let currentValueFirst = (parseFloat(quantityLeftFirst) * parseFloat(marketFirstBid));
            let quantityLeftSecond = (parseFloat(secondValue.Quantity) - parseFloat(secondValue.SoldAmount));
            let priceSecond = (parseFloat(secondValue.PricePerUnit) * parseFloat(secondValue.Quantity));
            let currentValueSecond = (parseFloat(quantityLeftSecond) * parseFloat(marketSecondBid));
            firstValue = (currentValueFirst - parseFloat(priceFirst)).toFixed(2);
            secondValue = (currentValueSecond - parseFloat(priceSecond)).toFixed(2);
          }

          break;
        case "Costs":
          firstValue = (parseFloat(firstValue.PricePerUnit) * parseFloat(firstValue.Quantity));
          secondValue = (parseFloat(secondValue.PricePerUnit) * parseFloat(secondValue.Quantity));
          break;
        default:
          firstValue = firstValue[this.state.sortField];
          secondValue = secondValue[this.state.sortField];
      }

      if(/(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/gm.test(firstValue)) {
        let parts_a = firstValue.split(/\D/),
            parts_b = secondValue.split(/\D/);
        return new Date(parts_b[0], --parts_b[1], parts_b[2], parts_b[3], parts_b[4], parts_b[5]) - new Date(parts_a[0], --parts_a[1], parts_a[2], parts_a[3], parts_a[4], parts_a[5]);
      } else if(!isNaN(firstValue)) {
        return secondValue - firstValue;
      } else {
        if (secondValue < firstValue) return -1;
        if (secondValue > firstValue) return 1;
        return 0;
      }
    })

    let pagination = [];
    const addPage = (s, f) => {
      for (let i = s; i < f; i++) {
        pagination.push( 
          <Link
          key={i}
          to="/tpos"
          className={`paginate_button ${
            this.state.activePage === i ? "current" : ""
          } `}
          onClick={() => this.setState({ activePage: i })}
          style={{ display: "inline-block" }}
        >
          {i}
        </Link>);
      }
    };

    const firstPage = () => {
      pagination.push(
          <span>
            <Link
            key="1"
            to="/tpos"
            className={`paginate_button ${
              this.state.activePage === 1 ? "current" : ""
            } `}
            onClick={() => this.setState({ activePage: 1 })}
            style={{ display: "inline-block" }}
          >
            1
          </Link>
          <span className={`paginate_button disabled`} style={{ display: "inline-block" }}>...</span>
        </span>);
    }; 

    const lastPage = () => {
      pagination.push(
          <span>
            <span className={`paginate_button disabled`} style={{ display: "inline-block" }}>...</span>
            <Link
              key={totalPages}
              to="/tpos"
              className={`paginate_button ${
                this.state.activePage === totalPages ? "current" : ""
              } `}
              onClick={() => this.setState({ activePage: totalPages })}
              style={{ display: "inline-block" }}
            >
              {totalPages}
            </Link>
          </span>
          );
    }; 

    if (totalPages < this.state.paginationSteps * 2 + 6) {
      addPage(1, totalPages + 1);
    } else if (this.state.activePage < this.state.paginationSteps * 2 + 1) {
      addPage(1, this.state.paginationSteps * 2 + 4);
      lastPage();
    } else if (this.state.activePage > totalPages - this.state.paginationSteps * 2) {
      firstPage();
      addPage(totalPages - this.state.paginationSteps * 2 - 2, totalPages + 1);
    } else {
      firstPage();
      addPage(this.state.activePage - this.state.paginationSteps, this.state.activePage + this.state.paginationSteps + 1);
      lastPage();
    }

    return (
        <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive table-hover fs-14">
            <div id="example5_wrapper" className="dataTables_wrapper no-footer">
            <table className="table display mb-4 dataTablesCard border-no card-table text-black dataTable no-footer" id="transaction_table">
            <thead>
              <tr role="row" className="font-w600">
                <th style={{cursor: "pointer", paddingTop: '10px', paddingBottom: '10px'}} onClick={() => this.setState({ sortField: "TSstart", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} rowSpan={1} colSpan={1}>
                  Date {this.state.sortField === "TSstart" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                </th>
                <th style={{cursor: "pointer", padding: "10px 0"}} onClick={() => this.setState({ sortField: "Exchange", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} rowSpan={1} colSpan={1}>
                  Asset {this.state.sortField === "Exchange" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                </th>
                <th style={{cursor: "pointer", padding: "10px 0"}} onClick={() => this.setState({ sortField: "PricePerUnit", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} rowSpan={1} colSpan={1}>
                  Price {this.state.sortField === "PricePerUnit" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                </th>
                <th style={{cursor: "pointer", padding: "10px 0"}} onClick={() => this.setState({ sortField: "Quantity", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} rowSpan={1} colSpan={1}>
                  Coins {this.state.sortField === "Quantity" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                </th>
                <th style={{cursor: "pointer", padding: "10px 0"}} onClick={() => this.setState({ sortField: "Ratio", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} rowSpan={1} colSpan={1}>
                  Ratio {this.state.sortField === "Ratio" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                </th>
                <th style={{cursor: "pointer", padding: "10px 0"}} onClick={() => this.setState({ sortField: "Costs", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} rowSpan={1} colSpan={1}>
                  Costs {this.state.sortField === "Costs" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                </th>
                <th style={{cursor: "pointer", padding: "10px 0"}} onClick={() => this.setState({ sortField: "Value", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} rowSpan={1} colSpan={1}>
                  Value {this.state.sortField === "Value" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                </th>
                <th style={{cursor: "pointer", padding: "10px 0"}} onClick={() => this.setState({ sortField: "SoldAmount", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} rowSpan={1} colSpan={1}>
                  Coins Sold {this.state.sortField === "SoldAmount" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                </th>
                <th style={{cursor: "pointer", padding: "10px 0"}} onClick={() => this.setState({ sortField: "SoldProfit", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} rowSpan={1} colSpan={1}>
                  <Icon name="usdt" size={20} /> Sold {this.state.sortField === "SoldProfit" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                </th>
                <th style={{cursor: "pointer", padding: "10px 0"}} onClick={() => this.setState({ sortField: "Next", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} rowSpan={1} colSpan={1}>
                  <Icon name="usdt" size={20} /> Next {this.state.sortField === "Next" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                </th>
              </tr>
            </thead>
              <tbody>
                {data.slice((this.state.activePage - 1) * this.state.itemsPerPage, (this.state.activePage) * this.state.itemsPerPage).map((order, i) => {
                  let oddOrEven = (i % 2 == 0) ? "even" : "odd";

                  let market = this.getMarket(order.MarketID);
                  let marketBid = (market !== null) ? market.Bid : 0;
                  let quantityLeft = (parseFloat(order.Quantity) - parseFloat(order.SoldAmount));
                  let price = (parseFloat(order.PricePerUnit) * parseFloat(order.Quantity));
                  let percentChange = ((parseFloat(marketBid) * 100) / parseFloat(order.PricePerUnit) - 100).toFixed(2);
                  let currentValue = (parseFloat(quantityLeft) * parseFloat(marketBid));
                  let saleProfit = (currentValue - parseFloat(price)).toFixed(2);

                  let className = (percentChange < 0) ? 'text-danger' : 'text-success';
                  let icon = (percentChange < 0) ? 'fa-caret-down' : 'fa-caret-up';

                  let classNameProfit = (saleProfit < 0) ? 'text-danger' : 'text-success';

                  let date = new Date(parseFloat(order.TSstart) * 1000);
                  let year = date.getFullYear()
                  let month = String(date.getMonth() + 1).padStart(2, '0')
                  let day = String(date.getDate()).padStart(2, '0')
                  
                  if(!isFinite(percentChange) || isNaN(percentChange)) percentChange = 0;

                  return (
                      <tr role="row" key={i} className={oddOrEven}>
                          <td>{`${year}-${month}-${day}`}</td>
                          <td style={{padding: "10px 0"}}>
                            <Link to={`/portfolio#${order.Exchange.substring(5)}`}>
                              <Icon name={order.Exchange.substring(5).toLowerCase()} size={20} />
                              <span className="text-black ml-1">{order.Exchange.substring(5)}</span>
                            </Link>
                          </td>
                          <td style={{color: "#50af95", padding: "10px 0"}}><Icon name="usdt" size={20} /> {this.context.numberFormat(parseFloat(order.PricePerUnit), (order.PricePerUnit < 1 ? 5 : 2))}</td>
                          <td style={{padding: "10px 0"}}>{this.context.numberFormat(parseFloat(order.Quantity), 8)}</td>
                          <td style={{padding: "10px 0"}}>
                              <p className={`mb-0 wspace-no ${className}`}>
                                  <i
                                  className={`fa ${icon} scale5 mr-2`}
                                  aria-hidden="true"
                                  />
                                  {percentChange}%
                              </p>
                          </td>
                          <td style={{color: "#50af95", padding: "10px 0"}}><Icon name="usdt" size={20} /> {this.context.numberFormat(parseFloat(price), 2)}</td>
                          <td style={{color: "#50af95", padding: "10px 0"}}><Icon name="usdt" size={20} /> {this.context.numberFormat(parseFloat(currentValue), 2)}</td>
                          <td style={{padding: "10px 0"}}>{this.context.numberFormat(parseFloat(order.SoldAmount), 8)}</td>
                          <td style={{padding: "10px 0"}} className="text-success">{this.context.numberFormat(parseFloat(order.SoldProfit), 2)}</td>
                          <td style={{padding: "10px 0"}} className={`${classNameProfit}`}>{saleProfit > 0 && "("}{this.context.numberFormat(parseFloat(saleProfit), 2)}{saleProfit > 0 && ")"}</td>
                      </tr>
                  )
              })}
              </tbody>
            </table>
            <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                <div className="dataTables_info">
                  Showing {(this.state.activePage - 1) * this.state.itemsPerPage + 1} to{" "}
                  {totalLength > (this.state.activePage + 1) * this.state.itemsPerPage
                    ? (this.state.activePage) * this.state.itemsPerPage
                    : totalLength}{" "}
                  of {totalLength} entries
                </div>
                <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="example5_paginate"
                >
                  <Link
                    className="paginate_button previous disabled"
                    to="/tpos"
                    onClick={() =>
                      this.state.activePage > 1 && this.setState({ activePage: this.state.activePage - 1 })
                    }
                  >
                    <i className="fa fa-arrow-left" />
                  </Link>
                  {pagination.map((page, i) => <span key={i}> {page} </span>)}
                  <Link
                    className="paginate_button next"
                    to="/tpos"
                    onClick={() =>
                      this.state.activePage < totalPages &&
                      this.setState({ activePage: this.state.activePage + 1 })
                    }
                  >
                    <i className="fa fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(TradeProfitOrders);