/// Menu
import MetisMenu from "metismenujs";
import React, { Component, useContext, useEffect } from "react";
import { useLocation } from 'react-router';

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { MainContext } from "../../../context/MainContext";
class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
    //  this.mm("dispose");
    // console.log(this.mm);
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);
  const {
    user
  } = useContext(MainContext);
  const { pathname } = useLocation();
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
	
    //sidebar icon Heart blast
    var handleheartBlast = document.querySelector('.heart');
    function heartBlast() {
        return handleheartBlast.classList.toggle("heart-blast");
    }

    handleheartBlast.addEventListener('click', heartBlast);
  }, []);
  let scrollPosition = useScrollPosition();
  /// Path
  let path = pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  let dashboard = [
      "",
      "dashboard",
    ],
    portfolio = [
      "portfolio",
    ],
    autotrades = [
      "autotrades",
    ],
    tpos = [
      "tpos",
    ],
    exchange = [
      "exchange",
    ],
    markets = [
      "markets",
    ];
  return (
    <div
      className={`deznav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? scrollPosition > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          <li className={`${dashboard.includes(path) ? "mm-active" : ""}`}>
            <Link to="/" className="ai-icon" aria-expanded="false">
              <i className="flaticon-381-pad"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
          </li>

          <li className={`${portfolio.includes(path) ? "mm-active" : ""}`}>
            <Link to="/portfolio" className="ai-icon" aria-expanded="false">
              <i className="flaticon-381-heart"></i>
              <span className="nav-text">Portfolio</span>
            </Link>
          </li>

          <li className={`${autotrades.includes(path) ? "mm-active" : ""}`}>
            <Link to="/autotrades" className="ai-icon" aria-expanded="false">
              <i className="flaticon-381-controls-3"></i>
              <span className="nav-text">Auto Trades</span>
            </Link>
          </li>

          <li className={`${tpos.includes(path) ? "mm-active" : ""}`}>
            <Link to="/tpos" className="ai-icon" aria-expanded="false">
              <i className="flaticon-381-fast-forward"></i>
              <span className="nav-text">Trade Profit Orders</span>
            </Link>
          </li>

          <li className={`${exchange.includes(path) ? "mm-active" : ""}`}>
            <Link to="/exchange" className="ai-icon" aria-expanded="false">
              <i className="flaticon-381-internet"></i>
              <span className="nav-text">Exchange History</span>
            </Link>
          </li>

          {/* <li className={`${markets.includes(path) ? "mm-active" : ""}`}>
            <Link to="/markets" className="ai-icon" aria-expanded="false">
              <i className="flaticon-381-network"></i>
              <span className="nav-text">Markets</span>
            </Link>
          </li> */}
        </MM>
        <hr />
        <div className="copyright">
          <p>
            <strong>CryptoByte</strong> © 2022 Alle rechten voorbehouden
          </p>
          <p className="fs-12">
            Hier voelt elke dag als een ATH <span className="heart" />
          </p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
