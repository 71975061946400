import React from "react";
import { withRouter, Link } from "react-router-dom";
import { MainContext } from "../../../context/MainContext";
import Icon from "react-crypto-icons";
import { Col, Card } from "react-bootstrap";

class Autotrades extends React.Component {
  static contextType = MainContext

  constructor(props) {
    super(props)

      this.state = {
        result: [],
        activeBuyPage: 1,
        activeSellPage: 1,
        itemsPerPage: 10,
        paginationSteps: 1
      }
  }

  componentDidMount() {
    fetch(global.url + 'index.php?route=app/app/home').then(res => res.json())
      .then(
        (result) => {        
          if(!result.user || result.user == null || !result.user.id || result.user.id == 0) {
            this.context.logOutUser();
            this.props.history.push("/login");
            return;
          }
          result.data.autotrades.sort(function(a, b){
            var parts_a = a.Closed.split(/\D/),
                parts_b = b.Closed.split(/\D/);
            return new Date(parts_b[0], --parts_b[1], parts_b[2], parts_b[3], parts_b[4], parts_b[5]) - new Date(parts_a[0], --parts_a[1], parts_a[2], parts_a[3], parts_a[4], parts_a[5]);
          })    
          this.setState({
            result: result.data
          })
        },
        (error) => {
            console.log("Ophalen mislukt! ", error);
        }
      ).then(window.scrollTo(0, 0));
  }

  render() {
    if (this.state.result == null || this.state.result.length === 0) {
      return (
        <div className="row">
          <Col xl={12} lg={12}>
            <Card>
              <Card.Header>
                <Card.Title>Loading</Card.Title>
              </Card.Header>
              <Card.Body>
                Data is being loaded from server, please wait...
              </Card.Body>
            </Card>
          </Col>
        </div>
      )
    }

    const originalBuyData = (this.state.result.autotrades || []).filter(autotrade => autotrade.type == 'buy'),
          buyData = originalBuyData.map(object => ({ ...object })),
          originalSellData = (this.state.result.autotrades || []).filter(autotrade => autotrade.type == 'sell'),
          sellData = originalSellData.map(object => ({ ...object })),
          totalBuyPages = Math.ceil(buyData.length / this.state.itemsPerPage),
          totalSellPages = Math.ceil(sellData.length / this.state.itemsPerPage);

    let buyPagination = [],
        sellPagination = [];
    const addPage = (t, s, f) => {
      for (let i = s; i < f; i++) {
        if(t === 1) {
          buyPagination.push( 
            <Link
            key={i}
            to="/autotrades"
            className={`paginate_button ${
              this.state.activeBuyPage === i ? "current" : ""
            } `}
            onClick={() => this.setState({ activeBuyPage: i })}
            style={{ display: "inline-block" }}
          >
            {i}
          </Link>);
        } else {
          sellPagination.push( 
            <Link
            key={i}
            to="/autotrades"
            className={`paginate_button ${
              this.state.activeSellPage === i ? "current" : ""
            } `}
            onClick={() => this.setState({ activeSellPage: i })}
            style={{ display: "inline-block" }}
          >
            {i}
          </Link>);
        }
      }
    };

    const firstPage = (t) => {
      if(t === 1) {
        buyPagination.push(
          <span>
            <Link
            key="1"
            to="/autotrades"
            className={`paginate_button ${
              this.state.activeBuyPage === 1 ? "current" : ""
            } `}
            onClick={() => this.setState({ activeBuyPage: 1 })}
            style={{ display: "inline-block" }}
          >
            1
          </Link>
          <span className={`paginate_button disabled`} style={{ display: "inline-block" }}>...</span>
        </span>);
      } else {
        sellPagination.push(
          <span>
            <Link
            key="1"
            to="/autotrades"
            className={`paginate_button ${
              this.state.activeSellPage === 1 ? "current" : ""
            } `}
            onClick={() => this.setState({ activeSellPage: 1 })}
            style={{ display: "inline-block" }}
          >
            1
          </Link>
          <span className={`paginate_button disabled`} style={{ display: "inline-block" }}>...</span>
        </span>);
      }
    }; 

    const lastPage = (t) => {
      if(t === 1) {
        buyPagination.push(
          <span>
            <span className={`paginate_button disabled`} style={{ display: "inline-block" }}>...</span>
            <Link
              key={totalBuyPages}
              to="/autotrades"
              className={`paginate_button ${
                this.state.activeBuyPage === totalBuyPages ? "current" : ""
              } `}
              onClick={() => this.setState({ activeBuyPage: totalBuyPages })}
              style={{ display: "inline-block" }}
            >
              {totalBuyPages}
            </Link>
          </span>
          );
      } else {
        sellPagination.push(
          <span>
            <span className={`paginate_button disabled`} style={{ display: "inline-block" }}>...</span>
            <Link
              key={totalSellPages}
              to="/autotrades"
              className={`paginate_button ${
                this.state.activeSellPage === totalSellPages ? "current" : ""
              } `}
              onClick={() => this.setState({ activeSellPage: totalSellPages })}
              style={{ display: "inline-block" }}
            >
              {totalSellPages}
            </Link>
          </span>
          );
      }
    }; 

    if (totalBuyPages < this.state.paginationSteps * 2 + 6) {
      addPage(1, 1, totalBuyPages + 1);
    } else if (this.state.activeBuyPage < this.state.paginationSteps * 2 + 1) {
      addPage(1, 1, this.state.paginationSteps * 2 + 4);
      lastPage(1);
    } else if (this.state.activeBuyPage > totalBuyPages - this.state.paginationSteps * 2) {
      firstPage(1);
      addPage(1, totalBuyPages - this.state.paginationSteps * 2 - 2, totalBuyPages + 1);
    } else {
      firstPage(1);
      addPage(1, this.state.activeBuyPage - this.state.paginationSteps, this.state.activeBuyPage + this.state.paginationSteps + 1);
      lastPage(1);
    }

    if (totalSellPages < this.state.paginationSteps * 2 + 6) {
      addPage(2, 1, totalSellPages + 1);
    } else if (this.state.activeSellPage < this.state.paginationSteps * 2 + 1) {
      addPage(2, 1, this.state.paginationSteps * 2 + 4);
      lastPage(2);
    } else if (this.state.activeSellPage > totalSellPages - this.state.paginationSteps * 2) {
      firstPage(2);
      addPage(2, totalSellPages - this.state.paginationSteps * 2 - 2, totalSellPages + 1);
    } else {
      firstPage(2);
      addPage(2, this.state.activeSellPage - this.state.paginationSteps, this.state.activeSellPage + this.state.paginationSteps + 1);
      lastPage(2);
    }

    return (
      <div className="dataTables_wrapper no-footer">
        <div className="row">
        <Col xl={6} lg={6}>
          <Card>
            <Card.Header>
              <Card.Title>Auto Buys</Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="table-responsive table-hover fs-14">
              <div id="example5_wrapper" className="dataTables_wrapper no-footer">
                <table
                  className="table display dataTablesCard card-table text-black dataTable no-footer"
                  id="transaction_table"
                  role="grid"
                  aria-describedby="example5_info"
                >
                  <thead>
                    <th
                      tabIndex={0}
                      rowSpan={1}
                      colSpan={2}
                      style={{padding: "0px 0px 10px 0"}}
                    >
                      Coin
                    </th>
                    <th
                      tabIndex={0}
                      rowSpan={1}
                      colSpan={1}
                      style={{padding: "0px 0px 10px 0"}}
                    >
                      Date
                    </th>
                    <th
                      tabIndex={0}
                      rowSpan={1}
                      colSpan={1}
                      className="text-right"
                      style={{padding: "0px 0px 10px 0"}}
                    >
                      Price
                    </th>
                    <th
                      tabIndex={0}
                      rowSpan={1}
                      colSpan={1}
                      className="text-right"
                      style={{padding: "0px 0px 10px 0"}}
                    >
                      Total
                    </th>
                  </thead>
                  <tbody>
                  {buyData.slice((this.state.activeBuyPage - 1) * this.state.itemsPerPage, (this.state.activeBuyPage) * this.state.itemsPerPage).map((autotrade, i) => {
                    let oddOrEven = (i % 2 == 0) ? "even" : "odd";
                    let className = (autotrade.type == 'buy') ? "danger" : "success";
                    let svgPath = (className == 'danger') ? "M20.7529 19.1563L7.5703 5.97367C7.00891 5.41228 6.09876 5.41228 5.53737 5.97367C4.97598 6.53505 4.97598 7.44521 5.53737 8.0066L18.72 21.1892L15.2913 21.1809L15.2912 21.1809C14.4973 21.179 13.8522 21.8211 13.8503 22.615C13.8484 23.4091 14.4907 24.054 15.2844 24.056L15.2856 23.556L15.2844 24.056L22.1954 24.0727L22.2028 24.0727L22.2051 24.0726C22.9947 24.0692 23.6329 23.4284 23.6363 22.6414L23.6363 22.6391L23.6363 22.6317L23.6196 15.7207L23.6196 15.7207C23.6177 14.9268 22.9727 14.2847 22.1787 14.2866C21.3847 14.2885 20.7427 14.9336 20.7446 15.7275L20.7446 15.7275L20.7529 19.1563Z" : "M7.99954 10.4687L21.1821 23.6513C21.7435 24.2127 22.6537 24.2127 23.2151 23.6513C23.7765 23.0899 23.7765 22.1798 23.2151 21.6184L10.0325 8.43582L13.4612 8.4441L13.4612 8.44409C14.2551 8.44598 14.9002 7.80394 14.9021 7.01004C14.904 6.21593 14.2617 5.57098 13.468 5.56905L13.4668 6.06905L13.468 5.56905L6.55703 5.55234L6.54969 5.55232L6.54737 5.55239C5.75771 5.55578 5.11953 6.19662 5.11616 6.98358L5.1161 6.98585L5.11612 6.99333L5.13282 13.9043L5.13282 13.9043C5.13475 14.6982 5.77979 15.3403 6.57378 15.3384C7.36769 15.3365 8.00975 14.6914 8.00787 13.8975L8.00787 13.8975L7.99954 10.4687Z";
                    return (
                      <tr role="row" className={oddOrEven}>
                        <td style={{padding: "10px 0"}}>
                          <span className={`bg-${className} ic-icon`} style={{width: 32, height: 32, minWidth: 32, lineHeight: "32px"}}>
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 29 29"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d={svgPath}
                                fill="white"
                                stroke="white"
                              />
                            </svg>
                          </span>
                        </td>
                        <td style={{padding: "10px"}}>
                          <Link to={`/portfolio#${autotrade.MarketName.substring(5)}`}>
                            <Icon name={autotrade.MarketName.substring(5).toLowerCase()} size={20} />
                            <span className="text-black ml-2">{autotrade.MarketName.substring(5)}</span>
                          </Link>
                        </td>
                        <td style={{padding: "10px 0"}}>{autotrade.Closed}</td>
                        <td style={{padding: "10px 0"}}>
                          <span className="text-black font-w700 float-right">{this.context.numberFormat(autotrade.PricePU, 2)}</span>
                        </td>
                        <td style={{padding: "10px 0"}}>
                          <span className={`font-w700 btn-link text-${className} float-right`}><Icon name="usdt" size={20} /> {this.context.numberFormat(autotrade.Price, 2)}</span>
                        </td>
                      </tr>
                    )
                  })}
                  </tbody>
                </table>
                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                  <div className="dataTables_info">
                    Showing {(this.state.activeBuyPage - 1) * this.state.itemsPerPage + 1} to{" "}
                    {originalBuyData.length > (this.state.activeBuyPage + 1) * this.state.itemsPerPage
                      ? (this.state.activeBuyPage) * this.state.itemsPerPage
                      : originalBuyData.length}{" "}
                    of {originalBuyData.length} entries
                  </div>
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="example5_paginate"
                  >
                    <Link
                      className="paginate_button previous disabled"
                      to="/autotrades"
                      onClick={() =>
                        this.state.activeBuyPage > 1 && this.setState({ activeBuyPage: this.state.activeBuyPage - 1 })
                      }
                    >
                      <i className="fa fa-arrow-left" />
                    </Link>
                    {buyPagination.map((page, i) => <span key={i}> {page} </span>)}
                    <Link
                      className="paginate_button next"
                      to="/autotrades"
                      onClick={() =>
                        this.state.activeBuyPage < totalBuyPages &&
                        this.setState({ activeBuyPage: this.state.activeBuyPage + 1 })
                      }
                    >
                      <i className="fa fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={6} lg={6}>
          <Card>
            <Card.Header>
              <Card.Title>Auto Sells</Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="table-responsive table-hover fs-14">
              <div id="example5_wrapper" className="dataTables_wrapper no-footer">
                <table
                  className="table display dataTablesCard short-one card-table text-black dataTable no-footer"
                  id="transaction_table"
                  role="grid"
                  aria-describedby="example5_info"
                >
                  <thead>
                    <th
                      tabIndex={0}
                      rowSpan={1}
                      colSpan={2}
                      style={{padding: "0px 0px 10px 0"}}
                    >
                      Coin
                    </th>
                    <th
                      tabIndex={0}
                      rowSpan={1}
                      colSpan={1}
                      style={{padding: "0px 0px 10px 0"}}
                    >
                      Date
                    </th>
                    <th
                      tabIndex={0}
                      rowSpan={1}
                      colSpan={1}
                      className="text-right"
                      style={{padding: "0px 0px 10px 0"}}
                    >
                      Price
                    </th>
                    <th
                      tabIndex={0}
                      rowSpan={1}
                      colSpan={1}
                      className="text-right"
                      style={{padding: "0px 0px 10px 0"}}
                    >
                      Total
                    </th>
                  </thead>
                  <tbody>
                  {sellData.slice((this.state.activeSellPage - 1) * this.state.itemsPerPage, (this.state.activeSellPage) * this.state.itemsPerPage).map((autotrade, i) => {
                    let oddOrEven = (i % 2 == 0) ? "even" : "odd";
                    let className = (autotrade.type == 'buy') ? "danger" : "success";
                    let svgPath = (className == 'danger') ? "M20.7529 19.1563L7.5703 5.97367C7.00891 5.41228 6.09876 5.41228 5.53737 5.97367C4.97598 6.53505 4.97598 7.44521 5.53737 8.0066L18.72 21.1892L15.2913 21.1809L15.2912 21.1809C14.4973 21.179 13.8522 21.8211 13.8503 22.615C13.8484 23.4091 14.4907 24.054 15.2844 24.056L15.2856 23.556L15.2844 24.056L22.1954 24.0727L22.2028 24.0727L22.2051 24.0726C22.9947 24.0692 23.6329 23.4284 23.6363 22.6414L23.6363 22.6391L23.6363 22.6317L23.6196 15.7207L23.6196 15.7207C23.6177 14.9268 22.9727 14.2847 22.1787 14.2866C21.3847 14.2885 20.7427 14.9336 20.7446 15.7275L20.7446 15.7275L20.7529 19.1563Z" : "M7.99954 10.4687L21.1821 23.6513C21.7435 24.2127 22.6537 24.2127 23.2151 23.6513C23.7765 23.0899 23.7765 22.1798 23.2151 21.6184L10.0325 8.43582L13.4612 8.4441L13.4612 8.44409C14.2551 8.44598 14.9002 7.80394 14.9021 7.01004C14.904 6.21593 14.2617 5.57098 13.468 5.56905L13.4668 6.06905L13.468 5.56905L6.55703 5.55234L6.54969 5.55232L6.54737 5.55239C5.75771 5.55578 5.11953 6.19662 5.11616 6.98358L5.1161 6.98585L5.11612 6.99333L5.13282 13.9043L5.13282 13.9043C5.13475 14.6982 5.77979 15.3403 6.57378 15.3384C7.36769 15.3365 8.00975 14.6914 8.00787 13.8975L8.00787 13.8975L7.99954 10.4687Z";
                    return (
                      <tr role="row" className={oddOrEven}>
                        <td style={{padding: "10px 0"}}>
                          <span className={`bg-${className} ic-icon`} style={{width: 32, height: 32, minWidth: 32, lineHeight: "32px"}}>
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 29 29"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d={svgPath}
                                fill="white"
                                stroke="white"
                              />
                            </svg>
                          </span>
                        </td>
                        <td style={{padding: "10px"}}>
                          <Link to={`/portfolio#${autotrade.MarketName.substring(5)}`}>
                            <Icon name={autotrade.MarketName.substring(5).toLowerCase()} size={20} />
                            <span className="text-black ml-2">{autotrade.MarketName.substring(5)}</span>
                          </Link>
                        </td>
                        <td style={{padding: "10px 0"}}>{autotrade.Closed}</td>
                        <td style={{padding: "10px 0"}}>
                          <span className="text-black font-w700 float-right">{this.context.numberFormat(autotrade.PricePU, 2)}</span>
                        </td>
                        <td style={{padding: "10px 0"}}>
                          <span className={`font-w700 btn-link text-${className} float-right`}><Icon name="usdt" size={20} /> {this.context.numberFormat(autotrade.Price, 2)}</span>
                        </td>
                      </tr>
                    )
                  })}
                  </tbody>
                </table>
                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                  <div className="dataTables_info">
                    Showing {(this.state.activeSellPage - 1) * this.state.itemsPerPage + 1} to{" "}
                    {originalSellData.length > (this.state.activeSellPage + 1) * this.state.itemsPerPage
                      ? (this.state.activeSellPage) * this.state.itemsPerPage
                      : originalSellData.length}{" "}
                    of {originalSellData.length} entries
                  </div>
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="example5_paginate"
                  >
                    <Link
                      className="paginate_button previous disabled"
                      to="/autotrades"
                      onClick={() =>
                        this.state.activeSellPage > 1 && this.setState({ activeSellPage: this.state.activeSellPage - 1 })
                      }
                    >
                      <i className="fa fa-arrow-left" />
                    </Link>
                    {sellPagination.map((page, i) => <span key={i}> {page} </span>)}
                    <Link
                      className="paginate_button next"
                      to="/autotrades"
                      onClick={() =>
                        this.state.activeSellPage < totalSellPages &&
                        this.setState({ activeSellPage: this.state.activeSellPage + 1 })
                      }
                    >
                      <i className="fa fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            </Card.Body>
          </Card>
        </Col>
        </div>
      </div>
    )
  }
}

export default withRouter(Autotrades);