import React, { useContext } from "react";

/// React router dom
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
/// Dashboard
import Home from "./components/Dashboard/Home";
import Portfolio from "./components/pages/Portfolio";
import Autotrades from "./components/pages/Autotrades";
import TradeProfitOrders from "./components/pages/TradeProfitOrders";
import ExchangeHistory from "./components/pages/ExchangeHistory";

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import { ThemeContext } from "../context/ThemeContext";
import { MainContext } from "../context/MainContext";

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const { user } = useContext(MainContext);
  const routes = [
    /// Dashboard
    { url: "", component: Home, login: true },
    { url: "dashboard", component: Home, login: true },
    { url: "portfolio", component: Portfolio, login: true },
    { url: "autotrades", component: Autotrades, login: true },
    { url: "tpos", component: TradeProfitOrders, login: true },
    { url: "exchange", component: ExchangeHistory, login: true },

    /// pages
    { url: "page-register", component: Registration },
    { url: "page-lock-screen", component: LockScreen },
    { url: "login", component: Login, login: false },
    { url: "forgot-password", component: ForgotPassword, login: false },
    { url: "page-error-400", component: Error400 },
    { url: "page-error-403", component: Error403 },
    { url: "page-error-404", component: Error404 },
    { url: "page-error-500", component: Error500 },
    { url: "page-error-503", component: Error503 },
  ];
  return (
    <Router basename="/app">
      <div
        id={`${!(user == null) ? "main-wrapper" : ""}`}
        className={`${!(user == null) ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!(user == null) && <Nav />}

        <div className={`${!(user == null) ? "content-body" : ""}`}>
          <div
            className={`${!(user == null) ? "container-fluid" : ""}`}
            style={{ minHeight: window.innerHeight - 150 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                >
                  {user == null && data.login ? <Redirect to="/login" /> : "" }
                </Route>
              ))}
            </Switch>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default Markup;
