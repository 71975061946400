import React, { Component, createContext } from "react";

export const MainContext = createContext();

export default class MainContextProvider extends Component {
    constructor(props) {
        super(props);

        this.setUser = this.setUser.bind(this);
        this.logOutUser = this.logOutUser.bind(this);
        this.setExchange = this.setExchange.bind(this);
        this.numberFormat = this.numberFormat.bind(this);

        this.state = {
            user: JSON.parse(window.localStorage.getItem('user')) || null,
            setUser: this.setUser,
            logOutUser: this.logOutUser,
            setExchange: this.setExchange,
            numberFormat: this.numberFormat
        }
    }
   
    setUser(user) {
        window.localStorage.setItem('user', JSON.stringify(user));

        this.setState({user: user});
    }

    logOutUser() {
        window.localStorage.removeItem('user');

        this.setState({user: null});

        fetch(global.url + "index.php?route=app/app/logout");
    }
    
    setExchange(exchange) {
        fetch(global.url + "index.php?route=app/app/dummy&app_exchangeid=" + exchange).then(res => res.json()).then(
            (result) => {
                this.setUser(result.user);
            }
        );
    }
    
    numberFormat = (value, decimals) => {
        let result = parseFloat(value).toFixed(decimals);
        
        if(decimals > 0) result.replace(/\d(?=(\d{3})+\.)/g, '$&,');
        else result.replace(/\d(?=(\d{3})+)/g, '$&,');
        
        return result;
    }

    render() {
        return (
            <MainContext.Provider value={this.state}>
                {this.props.children}
            </MainContext.Provider>
        );
    }
}