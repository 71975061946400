import React from "react";
import { withRouter, Link } from "react-router-dom";
import { MainContext } from "../../../context/MainContext";
import Icon from "react-crypto-icons";
import { Col, Card, Dropdown } from "react-bootstrap";
import swal from "sweetalert";

const allowedGroups = [
  [0],
  [1,4],
  [1,8],
  [2,4],
  [2,8],
];

class Portfolio extends React.Component {
  static contextType = MainContext

  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      result: [],
      sortField: "Currency",
      sortOrder: "asc",
      asset: "",
      assetResult: [],
      activeBuyPage: 1,
      itemsPerBuyPage: 8,
      paginationBuySteps: 1,
      activeTpoPage: 1,
      itemsPerTpoPage: 12,
      paginationTpoSteps: 2,
      sortFieldTpo: "TSstart",
      sortOrderTpo: "desc",
      rangeSlider1: 0,
      rangeSlider2: 0,
    }
  }

  componentDidMount() {
    fetch(global.url + 'index.php?route=app/app/portfolio').then(res => res.json())
      .then(
        (result) => {
          if(!result.user || result.user == null || !result.user.id || result.user.id == 0) {
            this.context.logOutUser();
            this.props.history.push("/login");
            return;
          }            
            this.setState({
              result: result.data
            })
        },
        (error) => {
            console.log("Ophalen mislukt! ", error);
        }
      ).then(window.scrollTo(0, 0));
  }

  handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.target);

    fetch(global.url + 'index.php?route=app/app/saveAutoTrade', {
      method: 'POST',
      body: formData,
    }).then(res => res.json()).then(
      (result) => {
        if(result.result == 'failed') {
          swal("Failed!", result.resultText, "danger")
        } else {
          swal("Success!", result.resultText, "success")
        }
      },
      (error) => {
        swal("Failed!", error, "danger")
      }
    );
  }

  getPortfolio(asset) {
    fetch(global.url + 'index.php?route=app/app/portfolio&asset=' + asset).then(res => res.json())
      .then(
        (result) => {
          if(!result.user || result.user == null || !result.user.id || result.user.id == 0) {
            this.context.logOutUser();
            this.props.history.push("/login");
            return;
          }
            this.setState({
              asset: asset,
              assetResult: result.data
            })
            
            //this.props.history.push("/portfolio#" + this.state.asset);
        },
        (error) => {
            console.log("Ophalen mislukt! ", error);
        }
      ).then(window.scrollTo(0, 0));
  }

  componentWillMount() {
    if (this.props.location.hash !== '') {
        let asset = this.props.location.hash.replace("#","");
        
        if(this.state.asset !== asset) {
          this.setState({
            asset: asset
          })
          this.getPortfolio(asset);
        }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if(this.props.location.hash === '') {
          this.setState({
              asset: '',
              assetResult: []
            })
      } else {
          let asset = this.props.location.hash.replace("#","");

          if(this.state.asset !== asset) {
            this.setState({
              asset: asset
            })
            this.getPortfolio(asset);
          }
      }
    }
  }

  checkGroups(sCheckboxVal, sName) {
    var checkboxes = document.querySelectorAll("input[name='" + sName + "']");
    var isOneChecked = 0;
    var currentValue = sCheckboxVal;
    var allowedCheckboxes = [];

    for (var x = 0; x < checkboxes.length; x++) {
        if (checkboxes[x].checked === true) {
            isOneChecked++;
            currentValue = checkboxes[x].value;
        }
        checkboxes[x].disabled = false;
    }

    if (isOneChecked === 1) {
        allowedGroups.forEach(function(aGroup) {
            if (aGroup.indexOf(parseInt(currentValue)) >= 0) {
                for (x = 0; x < aGroup.length; x++) {
                    if(allowedCheckboxes.indexOf(parseInt(aGroup[x])) === -1) allowedCheckboxes.push(aGroup[x]);
                }
            }
        });

        for (var x = 0; x < checkboxes.length; x++) {
            checkboxes[x].disabled = (allowedCheckboxes.indexOf(parseInt(checkboxes[x].value)) === -1);
        }
    } else if(isOneChecked === 2) {
        for (var x = 0; x < checkboxes.length; x++) {
            if (checkboxes[x].checked !== true) checkboxes[x].disabled = true;
        }
    }
  }

  getMarket(marketID) {
    let originalArray = this.state.assetResult.markets || [],
        markets = Object.keys(originalArray).map(keyName => ({ ...originalArray[keyName] }));

    for (var i = 0; i < markets.length; i++) {
      if(markets[i].MarketID === marketID) {
        return markets[i];
      }
    }

    return null;
  }

  render() {
    if ((this.state.result == null || this.state.result.length === 0) && (this.state.assetResult == null || this.state.assetResult.length === 0)) {
      return (
        <div className="row">
          <Col xl={12} lg={12}>
            <Card>
              <Card.Header>
                <Card.Title>Loading</Card.Title>
              </Card.Header>
              <Card.Body>
                Data is being loaded from server, please wait...
              </Card.Body>
            </Card>
          </Col>
        </div>
      )
    }

    if (this.state.asset !== '') {
      if (this.state.assetResult == null || this.state.assetResult.length === 0) {
        return (
          <div className="row">
            <Col xl={12} lg={12}>
              <Card>
                <Card.Header>
                  <Card.Title>Loading</Card.Title>
                </Card.Header>
                <Card.Body>
                  Data is being loaded from server, please wait...
                </Card.Body>
              </Card>
            </Col>
          </div>
        )
      }

      const originalBuyData = (this.state.assetResult.autotrades || []),
          buyData = originalBuyData.map(object => ({ ...object })),
          totalBuyPages = Math.ceil(buyData.length / this.state.itemsPerBuyPage),
          originalTpoData = this.state.assetResult.profitOrders || [],
          tpoData = Object.keys(originalTpoData).map(keyName => ({ ...originalTpoData[keyName] })),
          totalTpoPages = Math.ceil(tpoData.length / this.state.itemsPerTpoPage),
          totalTpoLength = tpoData.length;

        tpoData.sort((a, b) => {
        let firstValue = this.state.sortOrder === "desc" ? a : b;
        let secondValue = this.state.sortOrder === "desc" ? b : a;
  
        switch(this.state.sortField) {
          case "Ratio":
          case "Value":
          case "Next":
            let marketFirst = this.getMarket(firstValue.MarketID);
            let marketFirstBid = (marketFirst !== null) ? marketFirst.Bid : 0;
            let marketSecond = this.getMarket(secondValue.MarketID);
            let marketSecondBid = (marketSecond !== null) ? marketSecond.Bid : 0;
  
            if(this.state.sortField === "Ratio") {
              firstValue = ((parseFloat(marketFirstBid) * 100) / parseFloat(firstValue.PricePerUnit) - 100).toFixed(2);
              secondValue = ((parseFloat(marketSecondBid) * 100) / parseFloat(secondValue.PricePerUnit) - 100).toFixed(2);
            } else if(this.state.sortField === "Value") {
              firstValue = (parseFloat(parseFloat(firstValue.Quantity) - parseFloat(firstValue.SoldAmount)) * parseFloat(marketFirstBid));
              secondValue = (parseFloat(parseFloat(secondValue.Quantity) - parseFloat(secondValue.SoldAmount)) * parseFloat(marketSecondBid));
            } else {
              let quantityLeftFirst = (parseFloat(firstValue.Quantity) - parseFloat(firstValue.SoldAmount));
              let priceFirst = (parseFloat(firstValue.PricePerUnit) * parseFloat(firstValue.Quantity));
              let currentValueFirst = (parseFloat(quantityLeftFirst) * parseFloat(marketFirstBid));
              let quantityLeftSecond = (parseFloat(secondValue.Quantity) - parseFloat(secondValue.SoldAmount));
              let priceSecond = (parseFloat(secondValue.PricePerUnit) * parseFloat(secondValue.Quantity));
              let currentValueSecond = (parseFloat(quantityLeftSecond) * parseFloat(marketSecondBid));
              firstValue = (currentValueFirst - parseFloat(priceFirst)).toFixed(2);
              secondValue = (currentValueSecond - parseFloat(priceSecond)).toFixed(2);
            }
  
            break;
          case "Costs":
            firstValue = (parseFloat(firstValue.PricePerUnit) * parseFloat(firstValue.Quantity));
            secondValue = (parseFloat(secondValue.PricePerUnit) * parseFloat(secondValue.Quantity));
            break;
          default:
            firstValue = firstValue[this.state.sortField];
            secondValue = secondValue[this.state.sortField];
        }
  
        if(/(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/gm.test(firstValue)) {
          let parts_a = firstValue.split(/\D/),
              parts_b = secondValue.split(/\D/);
          return new Date(parts_b[0], --parts_b[1], parts_b[2], parts_b[3], parts_b[4], parts_b[5]) - new Date(parts_a[0], --parts_a[1], parts_a[2], parts_a[3], parts_a[4], parts_a[5]);
        } else if(!isNaN(firstValue)) {
          return secondValue - firstValue;
        } else {
          if (secondValue < firstValue) return -1;
          if (secondValue > firstValue) return 1;
          return 0;
        }
      })
      
      let buyPagination = [];
      let tpoPagination = [];
      const addPage = (t, s, f) => {
        for (let i = s; i < f; i++) {
          if(t === 1) {
            buyPagination.push( 
              <span
              key={i}
              className={`paginate_button ${
                this.state.activeBuyPage === i ? "current" : ""
              } `}
              onClick={() => this.setState({ activeBuyPage: i })}
              style={{ display: "inline-block", cursor: 'pointer' }}
            >
              {i}
            </span>);
          } else {
            tpoPagination.push( 
              <span
              key={i}
              className={`paginate_button ${
                this.state.activeTpoPage === i ? "current" : ""
              } `}
              onClick={() => this.setState({ activeTpoPage: i })}
              style={{ display: "inline-block", cursor: 'pointer' }}
            >
              {i}
            </span>);
          }
        }
      };

      const firstPage = (t) => {
        if(t === 1) {
          buyPagination.push(
            <span>
              <span
              key="1"
              className={`paginate_button ${
                this.state.activeBuyPage === 1 ? "current" : ""
              } `}
              onClick={() => this.setState({ activeBuyPage: 1 })}
              style={{ display: "inline-block", cursor: 'pointer' }}
            >
              1
            </span>
            <span className={`paginate_button disabled`} style={{ display: "inline-block" }}>...</span>
          </span>);
        } else {
          tpoPagination.push(
            <span>
              <span
              key="1"
              className={`paginate_button ${
                this.state.activeTpoPage === 1 ? "current" : ""
              } `}
              onClick={() => this.setState({ activeTpoPage: 1 })}
              style={{ display: "inline-block", cursor: 'pointer' }}
            >
              1
            </span>
            <span className={`paginate_button disabled`} style={{ display: "inline-block" }}>...</span>
          </span>);
        }
      }; 

      const lastPage = (t) => {
        if(t === 1) {
          buyPagination.push(
            <span>
              <span className={`paginate_button disabled`} style={{ display: "inline-block" }}>...</span>
              <span
                key={totalBuyPages}
                className={`paginate_button ${
                  this.state.activeBuyPage === totalBuyPages ? "current" : ""
                } `}
                onClick={() => this.setState({ activeBuyPage: totalBuyPages })}
                style={{ display: "inline-block", cursor: "pointer" }}
              >
                {totalBuyPages}
              </span>
            </span>
            );
        } else {
          tpoPagination.push(
            <span>
              <span className={`paginate_button disabled`} style={{ display: "inline-block" }}>...</span>
              <span
                key={totalTpoPages}
                className={`paginate_button ${
                  this.state.activeTpoPage === totalTpoPages ? "current" : ""
                } `}
                onClick={() => this.setState({ activeTpoPage: totalTpoPages })}
                style={{ display: "inline-block", cursor: "pointer" }}
              >
                {totalTpoPages}
              </span>
            </span>
            );
        }
      }; 

      if (totalBuyPages < this.state.paginationBuySteps * 2 + 6) {
        addPage(1, 1, totalBuyPages + 1);
      } else if (this.state.activeBuyPage < this.state.paginationBuySteps * 2 + 1) {
        addPage(1, 1, this.state.paginationBuySteps * 2 + 4);
        lastPage(1);
      } else if (this.state.activeBuyPage > totalBuyPages - this.state.paginationBuySteps * 2) {
        firstPage(1);
        addPage(1, totalBuyPages - this.state.paginationBuySteps * 2 - 2, totalBuyPages + 1);
      } else {
        firstPage(1);
        addPage(1, this.state.activeBuyPage - this.state.paginationBuySteps, this.state.activeBuyPage + this.state.paginationBuySteps + 1);
        lastPage(1);
      }

      if (totalTpoPages < this.state.paginationTpoSteps * 2 + 6) {
        addPage(2, 1, totalTpoPages + 1);
      } else if (this.state.activeTpoPage < this.state.paginationTpoSteps * 2 + 1) {
        addPage(2, 1, this.state.paginationTpoSteps * 2 + 4);
        lastPage(2);
      } else if (this.state.activeTpoPage > totalTpoPages - this.state.paginationTpoSteps * 2) {
        firstPage(2);
        addPage(2, totalTpoPages - this.state.paginationTpoSteps * 2 - 2, totalTpoPages + 1);
      } else {
        firstPage(2);
        addPage(2, this.state.activeTpoPage - this.state.paginationTpoSteps, this.state.activeTpoPage + this.state.paginationTpoSteps + 1);
        lastPage(2);
      }

      this.checkGroups(0, 'autoTradeCorrectVerkoopGrens[]');
      this.checkGroups(0, 'autoTradeCorrectAankoopGrens[]');

      return (
        <div className="row">
          <div className="col-xl-4 col-sm-6">
            <div className="widget-stat card">
              <div className="card-body p-4">
                <div className="media">
                  <span className="mr-3 bgl-warning text-warning">
                    <i className="la la-euro"></i>
                  </span>
                  <div className="media-body">
                    <p className="mb-1">Current Value</p>
                    <h3 className="mb-0 text-warning">&euro; {this.state.assetResult != null && this.context.numberFormat(((parseFloat(this.state.assetResult.portfolio.Portfolio.Balance) * parseFloat(this.state.assetResult.portfolio.Market.Bid)) * parseFloat(this.state.assetResult.USDTtoEUR)), 0)}</h3>
                    <small>Equals to {this.state.assetResult != null && this.context.numberFormat((parseFloat(this.state.assetResult.portfolio.Portfolio.Balance) * parseFloat(this.state.assetResult.portfolio.Market.Bid)), 0)} <small>USDT</small></small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-sm-6">
            <div className="widget-stat card">
              <div className="card-body p-4">
                <div className="media">
                  <span className="mr-3 bgl-success text-success">
                    <i className="la la-euro"></i>
                  </span>
                  <div className="media-body">
                    <p className="mb-1">Sold Profit</p>
                    <h3 className="mb-0 text-success">&euro; {this.state.assetResult != null && this.context.numberFormat(parseFloat(this.state.assetResult.tpoProfit) * parseFloat(this.state.assetResult.USDTtoEUR), 0)}</h3>
                    <small>Equals to  {this.state.assetResult != null && this.context.numberFormat(parseFloat(this.state.assetResult.tpoProfit), 0)} <small>USDT</small></small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-sm-6">
            <div className="widget-stat card">
              <div className="card-body p-4">
                <div className="media">
                  <span className="mr-3 bgl-info text-info">
                    <Icon name={this.state.asset.toLowerCase()} size={40} />
                  </span>
                  <div className="media-body">
                    <p className="mb-1">Total Amount</p>
                    <h3 className="mb-0 text-info">{this.state.assetResult != null && this.context.numberFormat(parseFloat(this.state.assetResult.portfolio.Portfolio.Balance), (this.state.assetResult.portfolio.Portfolio.Balance < 1 ? 5 : 2))}</h3>
                    <small>Total amount of {this.state.asset} in Bittrex</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="col-xl-4 col-sm-6">
            <div className="widget-stat card">
              <div className="card-body p-4">
                <div className="media">
                  <span className="mr-3 bgl-primary text-primary">
                    <i className="la la-chart-bar"></i>
                  </span>
                  <div className="media-body">
                    <p className="mb-1">Current Bid Price</p>
                    <h4 className="mb-0"><Icon name='usdt' size={24} /> {this.state.assetResult != null && this.context.numberFormat(parseFloat(this.state.assetResult.portfolio.Market.Bid), (this.state.assetResult.portfolio.Market.Bid < 1 ? 4 : 2))}</h4>
                    <span className={`badge badge-${this.state.assetResult != null && this.state.assetResult.verschil24uur < 0 ? 'danger' : 'success'}`}>{this.state.assetResult != null && parseFloat(this.state.assetResult.verschil24uur)}%</span><br />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-sm-6">
            <div className="widget-stat card">
              <div className="card-body p-4">
                <div className="media">
                  <span className="mr-3 bgl-primary text-primary">
                    <i className="la la-angle-down"></i>
                  </span>
                  <div className="media-body">
                    <p className="mb-1">Lowest Price in last day</p>
                    <h4 className="mb-0"><Icon name='usdt' size={24} /> {this.state.assetResult != null && this.context.numberFormat(parseFloat(this.state.assetResult.portfolio.Market.Low), (this.state.assetResult.portfolio.Market.Low < 1 ? 4 : 2))}</h4>
                    <span className={`badge badge-${this.state.assetResult != null && this.state.assetResult.verschilLow < 0 ? 'danger' : 'success'}`}>{this.state.assetResult != null && parseFloat(this.state.assetResult.verschilLow)}%</span><br />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-sm-6">
            <div className="widget-stat card">
              <div className="card-body p-4">
                <div className="media">
                  <span className="mr-3 bgl-primary text-primary">
                    <i className="la la-angle-up"></i>
                  </span>
                  <div className="media-body">
                    <p className="mb-1">Highest Price in last day</p>
                    <h4 className="mb-0"><Icon name='usdt' size={24} /> {this.state.assetResult != null && this.context.numberFormat(parseFloat(this.state.assetResult.portfolio.Market.High), (this.state.assetResult.portfolio.Market.High < 1 ? 4 : 2))}</h4>
                    <span className={`badge badge-${this.state.assetResult != null && this.state.assetResult.verschilHigh < 0 ? 'danger' : 'success'}`}>{this.state.assetResult != null && parseFloat(this.state.assetResult.verschilHigh)}%</span><br />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Col xl={4} lg={4}>
            <Card>
              <Card.Header>
                <Card.Title>Auto Trades</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="table-responsive table-hover fs-14">
                <div id="example5_wrapper" className="dataTables_wrapper no-footer">
                  <table
                    className="table display dataTablesCard card-table text-black dataTable no-footer"
                    id="transaction_table"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <th
                        tabIndex={0}
                        rowSpan={1}
                        colSpan={2}
                        style={{padding: "0px 0px 10px 0"}}
                      >
                        Type
                      </th>
                      <th
                        tabIndex={0}
                        rowSpan={1}
                        colSpan={1}
                        style={{padding: "0px 0px 10px 0"}}
                      >
                        Date
                      </th>
                      <th
                        tabIndex={0}
                        rowSpan={1}
                        colSpan={1}
                        className="text-right"
                        style={{padding: "0px 0px 10px 0"}}
                      >
                        Price
                      </th>
                      <th
                        tabIndex={0}
                        rowSpan={1}
                        colSpan={1}
                        className="text-right"
                        style={{padding: "0px 0px 10px 0"}}
                      >
                        Total
                      </th>
                    </thead>
                    <tbody>
                    {buyData.slice((this.state.activeBuyPage - 1) * this.state.itemsPerBuyPage, (this.state.activeBuyPage) * this.state.itemsPerBuyPage).map((autotrade, i) => {
                      let oddOrEven = (i % 2 == 0) ? "even" : "odd";
                      let className = (autotrade.type == 'buy') ? "danger" : "success";
                      let svgPath = (className == 'danger') ? "M20.7529 19.1563L7.5703 5.97367C7.00891 5.41228 6.09876 5.41228 5.53737 5.97367C4.97598 6.53505 4.97598 7.44521 5.53737 8.0066L18.72 21.1892L15.2913 21.1809L15.2912 21.1809C14.4973 21.179 13.8522 21.8211 13.8503 22.615C13.8484 23.4091 14.4907 24.054 15.2844 24.056L15.2856 23.556L15.2844 24.056L22.1954 24.0727L22.2028 24.0727L22.2051 24.0726C22.9947 24.0692 23.6329 23.4284 23.6363 22.6414L23.6363 22.6391L23.6363 22.6317L23.6196 15.7207L23.6196 15.7207C23.6177 14.9268 22.9727 14.2847 22.1787 14.2866C21.3847 14.2885 20.7427 14.9336 20.7446 15.7275L20.7446 15.7275L20.7529 19.1563Z" : "M7.99954 10.4687L21.1821 23.6513C21.7435 24.2127 22.6537 24.2127 23.2151 23.6513C23.7765 23.0899 23.7765 22.1798 23.2151 21.6184L10.0325 8.43582L13.4612 8.4441L13.4612 8.44409C14.2551 8.44598 14.9002 7.80394 14.9021 7.01004C14.904 6.21593 14.2617 5.57098 13.468 5.56905L13.4668 6.06905L13.468 5.56905L6.55703 5.55234L6.54969 5.55232L6.54737 5.55239C5.75771 5.55578 5.11953 6.19662 5.11616 6.98358L5.1161 6.98585L5.11612 6.99333L5.13282 13.9043L5.13282 13.9043C5.13475 14.6982 5.77979 15.3403 6.57378 15.3384C7.36769 15.3365 8.00975 14.6914 8.00787 13.8975L8.00787 13.8975L7.99954 10.4687Z";
                      return (
                        <tr role="row" className={oddOrEven}>
                          <td style={{padding: "10px 0"}}>
                            <span className={`bg-${className} ic-icon`} style={{width: 32, height: 32, minWidth: 32, lineHeight: "32px"}}>
                              <svg
                                width={20}
                                height={20}
                                viewBox="0 0 29 29"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d={svgPath}
                                  fill="white"
                                  stroke="white"
                                />
                              </svg>
                            </span>
                          </td>
                          <td style={{padding: "10px"}}>
                              <Icon name={autotrade.MarketName.substring(5).toLowerCase()} size={20} />
                          </td>
                          <td style={{padding: "10px 0"}}>{autotrade.TS}</td>
                          <td style={{padding: "10px 0"}}>
                            <span className="text-black font-w700 float-right">{this.context.numberFormat(autotrade.PricePU, 2)}</span>
                          </td>
                          <td style={{padding: "10px 0"}}>
                            <span className={`font-w700 btn-link text-${className} float-right`}><Icon name="usdt" size={20} /> {this.context.numberFormat(autotrade.Price, 2)}</span>
                          </td>
                        </tr>
                      )
                    })}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                    <div className="dataTables_info">
                      Showing {(this.state.activeBuyPage - 1) * this.state.itemsPerBuyPage + 1} to{" "}
                      {originalBuyData.length > (this.state.activeBuyPage + 1) * this.state.itemsPerBuyPage
                        ? (this.state.activeBuyPage) * this.state.itemsPerBuyPage
                        : originalBuyData.length}{" "}
                      of {originalBuyData.length} entries
                    </div>
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example5_paginate"
                    >
                      <span style={{cursor: 'pointer'}}
                        className="paginate_button previous disabled"
                        onClick={() =>
                          this.state.activeBuyPage > 1 && this.setState({ activeBuyPage: this.state.activeBuyPage - 1 })
                        }
                      >
                        <i className="fa fa-arrow-left" />
                      </span>
                      {buyPagination.map((page, i) => <span key={i}> {page} </span>)}
                      <span style={{cursor: 'pointer'}}
                        className="paginate_button next"
                        onClick={() =>
                          this.state.activeBuyPage < totalBuyPages &&
                          this.setState({ activeBuyPage: this.state.activeBuyPage + 1 })
                        }
                      >
                        <i className="fa fa-arrow-right" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={4} lg={4}>
            <Card>
              <Card.Header>
                <Card.Title>Autosell {this.state.asset}</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="basic-form">
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                      <div className="input-group input-group-lg">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            Sell above
                          </span>
                        </div>
                        <input type="hidden" name="autosell" value={this.state.assetResult.portfolio.Portfolio.dbpID} />
                        <input
                          type="number"
                          className="form-control"
                          name="autoTradeMinSellPrice"
                          placeholder={`> ${this.context.numberFormat(parseFloat(this.state.assetResult.portfolio.Market.High), (this.state.assetResult.portfolio.Market.High < 1 ? 4 : 2))} = 24u hoogste`}
                          defaultValue={this.state.assetResult.portfolio.Portfolio.autoTradeMinSellPrice}
                        />
                      </div>
                    </div>
                    <div className="form-group mb-0">
                      <div class="input-group input-group-lg">
                          <input type="range" name="autoTradeMinimalChange" min="1" max="150" step="1" class="form-control form-type" 
                            defaultValue={(this.state.assetResult.portfolio.Portfolio.autoTradeMinimalChange * 100) - 100}
                            onChange={(event) =>
                              this.setState({ rangeSlider1: event.target.value })
                            }
                          />
                          <div class="input-group-append">
                              <output style={{width: "45px", fontWeight: 'bold', padding: '13px 0px', textAlign: 'center'}} id="autoTradeMinimalChangeOutput">{this.state.rangeSlider1 != 0 ? this.state.rangeSlider1 : (this.state.assetResult.portfolio.Portfolio.autoTradeMinimalChange * 100) - 100}%</output>
                          </div>
                      </div>
                    </div>
                    <div className="custom-control custom-checkbox checkbox-success">
                      <input onChange={(e) => this.checkGroups(e.target.value, e.target.name)} id="customCheckBox1" name="autoTradeCorrectVerkoopGrens[]" value="1" defaultChecked={(this.state.assetResult.portfolio.Portfolio.autoTradeCorrectVerkoopGrens & 1) == 1} type="checkbox" className="custom-control-input" />
                      <label htmlFor="customCheckBox1" className="custom-control-label text-success" >
                        &nbsp; AutoSell price on price +%
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox checkbox-success">
                      <input onChange={(e) => this.checkGroups(e.target.value, e.target.name)} id="customCheckBox2" name="autoTradeCorrectVerkoopGrens[]" value="2" defaultChecked={(this.state.assetResult.portfolio.Portfolio.autoTradeCorrectVerkoopGrens & 2) == 2} type="checkbox" className="custom-control-input" />
                      <label htmlFor="customCheckBox2" className="custom-control-label text-success" >
                        &nbsp; AutoSell price higher than current price
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox checkbox-warning">
                      <input onChange={(e) => this.checkGroups(e.target.value, e.target.name)} id="customCheckBox3" name="autoTradeCorrectVerkoopGrens[]" value="8" defaultChecked={(this.state.assetResult.portfolio.Portfolio.autoTradeCorrectVerkoopGrens & 8) == 8} type="checkbox" className="custom-control-input" />
                      <label htmlFor="customCheckBox3" className="custom-control-label text-warning" >
                        &nbsp; AutoBuy price on current price -%
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox checkbox-warning">
                      <input onChange={(e) => this.checkGroups(e.target.value, e.target.name)} id="customCheckBox4" name="autoTradeCorrectVerkoopGrens[]" value="4" defaultChecked={(this.state.assetResult.portfolio.Portfolio.autoTradeCorrectVerkoopGrens & 4) == 4} type="checkbox" className="custom-control-input" />
                      <label htmlFor="customCheckBox4" className="custom-control-label text-warning" >
                        &nbsp; AutoBuy price on current price
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox checkbox-danger mb-3">
                      <input onChange={(e) => this.checkGroups(e.target.value, e.target.name)} id="customCheckBox5" name="autoTradeCorrectVerkoopGrens[]" value="0" defaultChecked={this.state.assetResult.portfolio.Portfolio.autoTradeCorrectVerkoopGrens == 0} type="checkbox" className="custom-control-input" />
                      <label htmlFor="customCheckBox5" className="custom-control-label text-danger" >
                        &nbsp; Do not change any price
                      </label>
                    </div>
                    <div className="form-group">
                      <div className="input-group input-group-lg">
                        <div className="input-group-prepend">
                          <span className="input-group-text ">
                            Min. threshold
                          </span>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          name="MinThresholdCoins"
                          placeholder={`100 USDT = ${this.context.numberFormat(100 / parseFloat(this.state.assetResult.portfolio.Market.Bid), 0)}`}
                          defaultValue={this.state.assetResult.portfolio.Portfolio.MinThresholdCoins}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group input-group-lg">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            Min. order size
                          </span>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Default $10.00"
                          name="MinSellOrder"
                          defaultValue={this.state.assetResult.portfolio.Portfolio.MinSellOrder}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            USDT
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-12">
                        <button
                          className="btn d-block btn-lg btn-success text-white w-100"
                          type="submit"
                        >
                          Set Autosell
                          <svg
                            className="ml-4 scale5"
                            width={16}
                            height={16}
                            viewBox="0 0 29 29"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.35182 13.4965L5.35182 13.4965L5.33512 6.58823C5.33508 6.5844 5.3351 6.58084 5.33514 6.57759M5.35182 13.4965L5.83514 6.58306L5.33514 6.58221C5.33517 6.56908 5.33572 6.55882 5.33597 6.5545L5.33606 6.55298C5.33585 6.55628 5.33533 6.56514 5.33516 6.57648C5.33515 6.57684 5.33514 6.57721 5.33514 6.57759M5.35182 13.4965C5.35375 14.2903 5.99878 14.9324 6.79278 14.9305C7.58669 14.9287 8.22874 14.2836 8.22686 13.4897L8.22686 13.4896L8.21853 10.0609M5.35182 13.4965L8.21853 10.0609M5.33514 6.57759C5.33752 5.789 5.97736 5.14667 6.76872 5.14454C6.77041 5.14452 6.77217 5.14451 6.77397 5.14451L6.77603 5.1445L6.79319 5.14456L13.687 5.16121L13.6858 5.66121L13.687 5.16121C14.4807 5.16314 15.123 5.80809 15.1211 6.6022C15.1192 7.3961 14.4741 8.03814 13.6802 8.03626L13.6802 8.03626L10.2515 8.02798L23.4341 21.2106C23.9955 21.772 23.9955 22.6821 23.4341 23.2435C22.8727 23.8049 21.9625 23.8049 21.4011 23.2435L8.21853 10.0609M5.33514 6.57759C5.33513 6.57959 5.33514 6.58159 5.33514 6.5836L8.21853 10.0609M6.77407 5.14454C6.77472 5.14454 6.77537 5.14454 6.77603 5.14454L6.77407 5.14454Z"
                              fill="white"
                              stroke="white"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={4} lg={4}>
            <Card>
              <Card.Header>
                <Card.Title>Autobuy {this.state.asset}</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="basic-form">
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                      <div className="input-group input-group-lg">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            Buy below
                          </span>
                        </div>
                        <input type="hidden" name="autobuy" value={this.state.assetResult.portfolio.Portfolio.dbpID} />
                        <input
                          type="number"
                          className="form-control"
                          name="autoTradeMaxBuyPrice"
                          placeholder={`< ${this.context.numberFormat(parseFloat(this.state.assetResult.portfolio.Market.Low), (this.state.assetResult.portfolio.Market.Low < 1 ? 4 : 2))} = 24u laagste`}
                          defaultValue={this.state.assetResult.portfolio.Portfolio.autoTradeMaxBuyPrice}
                        />
                      </div>
                    </div>
                    <div className="form-group mb-0">
                      <div class="input-group input-group-lg">
                          <input type="range" name="autoTradeMinimalChange2" min="1" max="50" step="1" class="form-control form-type" 
                            defaultValue={(this.state.assetResult.portfolio.Portfolio.autoTradeMinimalChange2 * 100) - 100}
                            onChange={(event) =>
                              this.setState({ rangeSlider2: event.target.value })
                            }
                          />
                          <div class="input-group-append">
                              <output style={{width: "45px", fontWeight: 'bold', padding: '13px 0px', textAlign: 'center'}} id="autoTradeMinimalChange2Output">{this.state.rangeSlider2 != 0 ? this.state.rangeSlider2 : (this.state.assetResult.portfolio.Portfolio.autoTradeMinimalChange2 * 100) - 100}%</output>
                          </div>
                      </div>
                    </div>
                    <div className="custom-control custom-checkbox checkbox-success">
                      <input onChange={(e) => this.checkGroups(e.target.value, e.target.name)} id="customCheckBox11" name="autoTradeCorrectAankoopGrens[]" value="1" defaultChecked={(this.state.assetResult.portfolio.Portfolio.autoTradeCorrectAankoopGrens & 1) == 1} type="checkbox" className="custom-control-input" />
                      <label htmlFor="customCheckBox11" className="custom-control-label text-success" >
                        &nbsp; Set buy price on current price -%
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox checkbox-success">
                      <input onChange={(e) => this.checkGroups(e.target.value, e.target.name)} id="customCheckBox12" name="autoTradeCorrectAankoopGrens[]" value="2" defaultChecked={(this.state.assetResult.portfolio.Portfolio.autoTradeCorrectAankoopGrens & 2) == 2} type="checkbox" className="custom-control-input" />
                      <label htmlFor="customCheckBox12" className="custom-control-label text-success" >
                        &nbsp; Set buy price lower than current price
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox checkbox-warning">
                      <input onChange={(e) => this.checkGroups(e.target.value, e.target.name)} id="customCheckBox13" name="autoTradeCorrectAankoopGrens[]" value="8" defaultChecked={(this.state.assetResult.portfolio.Portfolio.autoTradeCorrectAankoopGrens & 8) == 8} type="checkbox" className="custom-control-input" />
                      <label htmlFor="customCheckBox13" className="custom-control-label text-warning" >
                        &nbsp; Set sell price on current price +%
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox checkbox-warning">
                      <input onChange={(e) => this.checkGroups(e.target.value, e.target.name)} id="customCheckBox14" name="autoTradeCorrectAankoopGrens[]" value="4" defaultChecked={(this.state.assetResult.portfolio.Portfolio.autoTradeCorrectAankoopGrens & 4) == 4} type="checkbox" className="custom-control-input" />
                      <label htmlFor="customCheckBox14" className="custom-control-label text-warning" >
                        &nbsp; Set sell price on current price
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox checkbox-danger mb-3">
                      <input onChange={(e) => this.checkGroups(e.target.value, e.target.name)} id="customCheckBox15" name="autoTradeCorrectAankoopGrens[]" value="0" defaultChecked={this.state.assetResult.portfolio.Portfolio.autoTradeCorrectAankoopGrens == 0} type="checkbox" className="custom-control-input" />
                      <label htmlFor="customCheckBox15" className="custom-control-label text-danger" >
                        &nbsp; Do not change any price
                      </label>
                    </div>
                    <div className="form-group">
                      <div className="input-group input-group-lg">
                        <div className="input-group-prepend">
                          <span className="input-group-text ">
                            Max. threshold
                          </span>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          name="MaxThresholdCoins"
                          placeholder={`500 USDT = ${this.context.numberFormat(500 / parseFloat(this.state.assetResult.portfolio.Market.Bid), 0)}`}
                          defaultValue={this.state.assetResult.portfolio.Portfolio.MaxThresholdCoins}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group input-group-lg">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            Min. order size
                          </span>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          placeholder={`Default = $75.00`}
                          name="MinBuyOrder"
                          defaultValue={this.state.assetResult.portfolio.Portfolio.MinBuyOrder}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            USDT
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group input-group-lg">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            Max. order size
                          </span>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          placeholder={`Default = $100.00`}
                          name="MaxBuyOrder"
                          defaultValue={this.state.assetResult.portfolio.Portfolio.MaxBuyOrder}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            USDT
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-12">
                        <button
                          className="btn d-block btn-lg btn-danger text-white w-100"
                          type="submit"
                        >
                          Set Autobuy
                          <svg
                            className="ml-4 scale3"
                            width={16}
                            height={16}
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.9638 11.5104L16.9721 14.9391L3.78954 1.7565C3.22815 1.19511 2.31799 1.19511 1.75661 1.7565C1.19522 2.31789 1.19522 3.22805 1.75661 3.78943L14.9392 16.972L11.5105 16.9637L11.5105 16.9637C10.7166 16.9619 10.0715 17.6039 10.0696 18.3978C10.0677 19.1919 10.7099 19.8369 11.5036 19.8388L11.5049 19.3388L11.5036 19.8388L18.3976 19.8554L18.4146 19.8555L18.4159 19.8555C18.418 19.8555 18.42 19.8555 18.422 19.8555C19.2131 19.8533 19.8528 19.2114 19.8555 18.4231C19.8556 18.4196 19.8556 18.4158 19.8556 18.4117L19.8389 11.5035L19.8389 11.5035C19.8369 10.7097 19.1919 10.0676 18.3979 10.0695C17.604 10.0713 16.9619 10.7164 16.9638 11.5103L16.9638 11.5104Z"
                              fill="white"
                              stroke="white"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <div className="col-xl-12">
            <div className="table-responsive table-hover fs-14">
              <div id="example5_wrapper" className="dataTables_wrapper no-footer">
              <table className="table display mb-4 dataTablesCard border-no card-table text-black dataTable no-footer" id="transaction_table">
              <thead>
                <tr role="row" className="font-w600">
                  <th style={{cursor: "pointer", paddingTop: '10px', paddingBottom: '10px'}} onClick={() => this.setState({ sortField: "TSstart", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} rowSpan={1} colSpan={1}>
                    Date {this.state.sortField === "TSstart" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                  </th>
                  <th style={{cursor: "pointer", padding: "10px 0"}} onClick={() => this.setState({ sortField: "Costs", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} rowSpan={1} colSpan={1}>
                    Costs {this.state.sortField === "Costs" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                  </th>
                  <th style={{cursor: "pointer", padding: "10px 0"}} onClick={() => this.setState({ sortField: "Ratio", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} rowSpan={1} colSpan={1}>
                    Ratio {this.state.sortField === "Ratio" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                  </th>
                  <th style={{cursor: "pointer", padding: "10px 0"}} onClick={() => this.setState({ sortField: "SoldProfit", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} rowSpan={1} colSpan={1}>
                    <Icon name="usdt" size={20} /> Sold {this.state.sortField === "SoldProfit" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                  </th>
                  <th style={{cursor: "pointer", padding: "10px 0"}} onClick={() => this.setState({ sortField: "Value", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} rowSpan={1} colSpan={1}>
                    Value {this.state.sortField === "Value" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                  </th>
                  <th style={{cursor: "pointer", padding: "10px 0"}} onClick={() => this.setState({ sortField: "Quantity", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} rowSpan={1} colSpan={1}>
                    Coins {this.state.sortField === "Quantity" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                  </th>
                  <th style={{cursor: "pointer", padding: "10px 0"}} onClick={() => this.setState({ sortField: "SoldAmount", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} rowSpan={1} colSpan={1}>
                    Coins Sold {this.state.sortField === "SoldAmount" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                  </th>
                  <th style={{cursor: "pointer", padding: "10px 0"}} onClick={() => this.setState({ sortField: "Next", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} rowSpan={1} colSpan={1}>
                    <Icon name="usdt" size={20} /> Next {this.state.sortField === "Next" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                  </th>
                  <th style={{cursor: "pointer", padding: "10px 0"}} onClick={() => this.setState({ sortField: "PricePerUnit", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} rowSpan={1} colSpan={1}>
                    Price {this.state.sortField === "PricePerUnit" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                  </th>
                </tr>
              </thead>
                <tbody>
                  {tpoData.slice((this.state.activeTpoPage - 1) * this.state.itemsPerTpoPage, (this.state.activeTpoPage) * this.state.itemsPerTpoPage).map((order, i) => {
                    let oddOrEven = (i % 2 == 0) ? "even" : "odd";

                    let market = this.getMarket(order.MarketID);
                    let marketBid = (market !== null) ? market.Bid : 0;
                    let quantityLeft = (parseFloat(order.Quantity) - parseFloat(order.SoldAmount));
                    let price = (parseFloat(order.PricePerUnit) * parseFloat(order.Quantity));
                    let percentChange = ((parseFloat(marketBid) * 100) / parseFloat(order.PricePerUnit) - 100).toFixed(2);
                    let currentValue = (parseFloat(quantityLeft) * parseFloat(marketBid));
                    let saleProfit = (currentValue - parseFloat(price)).toFixed(2);

                    let className = (percentChange < 0) ? 'text-danger' : 'text-success';
                    let icon = (percentChange < 0) ? 'fa-caret-down' : 'fa-caret-up';

                    let classNameProfit = (saleProfit < 0) ? 'text-danger' : 'text-success';

                    let date = new Date(parseFloat(order.TSstart) * 1000);
                    let year = date.getFullYear()
                    let month = String(date.getMonth() + 1).padStart(2, '0')
                    let day = String(date.getDate()).padStart(2, '0')
                    
                    if(!isFinite(percentChange) || isNaN(percentChange)) percentChange = 0;

                    return (
                        <tr role="row" key={i} className={oddOrEven}>
                            <td>{`${year}-${month}-${day}`}</td>
                            <td style={{color: "#50af95", padding: "10px 0"}}><Icon name="usdt" size={20} /> {this.context.numberFormat(parseFloat(price), 2)}</td>
                            <td style={{padding: "10px 0"}}>
                                <p className={`mb-0 wspace-no ${className}`}>
                                    <i
                                    className={`fa ${icon} scale5 mr-2`}
                                    aria-hidden="true"
                                    />
                                    {percentChange}%
                                </p>
                            </td>
                            <td style={{padding: "10px 0"}} className="text-success">{this.context.numberFormat(parseFloat(order.SoldProfit), 2)}</td>
                            <td style={{color: "#50af95", padding: "10px 0"}}><Icon name="usdt" size={20} /> {this.context.numberFormat(parseFloat(currentValue), 2)}</td>
                            <td style={{padding: "10px 0"}}>{this.context.numberFormat(parseFloat(order.Quantity), 8)}</td>
                            <td style={{padding: "10px 0"}}>{this.context.numberFormat(parseFloat(order.SoldAmount), 8)}</td>
                            <td style={{padding: "10px 0"}} className={`${classNameProfit}`}>{saleProfit > 0 && "("}{this.context.numberFormat(parseFloat(saleProfit), 2)}{saleProfit > 0 && ")"}</td>
                            <td style={{color: "#50af95", padding: "10px 0"}}><Icon name="usdt" size={20} /> {this.context.numberFormat(parseFloat(order.PricePerUnit), (order.PricePerUnit < 1 ? 5 : 2))}</td>
                        </tr>
                    )
                })}
                </tbody>
              </table>
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                  <div className="dataTables_info">
                    Showing {(this.state.activeTpoPage - 1) * this.state.itemsPerTpoPage + 1} to{" "}
                    {totalTpoLength > (this.state.activeTpoPage + 1) * this.state.itemsPerTpoPage
                      ? (this.state.activeTpoPage) * this.state.itemsPerTpoPage
                      : totalTpoLength}{" "}
                    of {totalTpoLength} entries
                  </div>
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="example5_paginate2"
                  >
                    <span style={{cursor: 'pointer'}}
                        className="paginate_button previous disabled"
                        onClick={() =>
                          this.state.activeTpoPage > 1 && this.setState({ activeTpoPage: this.state.activeTpoPage - 1 })
                        }
                      >
                        <i className="fa fa-arrow-left" />
                      </span>
                    {tpoPagination.map((page, i) => <span key={i}> {page} </span>)}
                      <span style={{cursor: 'pointer'}}
                        className="paginate_button next"
                        onClick={() =>
                          this.state.activeTpoPage < totalTpoPages &&
                          this.setState({ activeTpoPage: this.state.activeTpoPage + 1 })
                        }
                      >
                        <i className="fa fa-arrow-right" />
                      </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    const originalData = this.state.result.portfolio || [],
          data = Object.keys(originalData).filter(keyName => this.state.result.portfolio[keyName].Portfolio.Balance > 0).map(keyName => ({ ...originalData[keyName] }));

    data.sort((a, b) => {
      let firstValue = this.state.sortOrder === "desc" ? a : b;
      let secondValue = this.state.sortOrder === "desc" ? b : a;

      switch(this.state.sortField) {
        case "AutoSell":
          firstValue = ((parseFloat(firstValue.Market.Bid) / parseFloat(firstValue.Portfolio.autoTradeMinSellPrice) * 100) - 100).toFixed(2);
          secondValue = ((parseFloat(secondValue.Market.Bid) / parseFloat(secondValue.Portfolio.autoTradeMinSellPrice) * 100) - 100).toFixed(2);

          if(!isFinite(firstValue) || isNaN(firstValue)) firstValue = 0;
          if(!isFinite(secondValue) || isNaN(secondValue)) secondValue = 0;
          break;
        case "AutoBuy":
          firstValue = ((parseFloat(firstValue.Market.Bid) / parseFloat(firstValue.Portfolio.autoTradeMaxBuyPrice) * 100) - 100).toFixed(2);
          secondValue = ((parseFloat(secondValue.Market.Bid) / parseFloat(secondValue.Portfolio.autoTradeMaxBuyPrice) * 100) - 100).toFixed(2);

          if(!isFinite(firstValue) || isNaN(firstValue)) firstValue = 0;
          if(!isFinite(secondValue) || isNaN(secondValue)) secondValue = 0;
          break;
        case "UsdtBalance":
          firstValue = (parseFloat(firstValue.Portfolio.Balance) * parseFloat(firstValue.Market.LastPrice));
          secondValue = (parseFloat(secondValue.Portfolio.Balance) * parseFloat(secondValue.Market.LastPrice));
          break;
        case "Change24h":
          let prevDay = (parseFloat(firstValue.Market.Low) + parseFloat(firstValue.Market.High)) / 2,
              prevDay2 = (parseFloat(secondValue.Market.Low) + parseFloat(secondValue.Market.High)) / 2;   
          firstValue = (((parseFloat(firstValue.Market.LastPrice) / prevDay) * 100) - 100).toFixed(2);
          secondValue = (((parseFloat(secondValue.Market.LastPrice) / prevDay2) * 100) - 100).toFixed(2);
          break;
        case "CurrentPrice":
          firstValue = parseFloat(firstValue.Market.Bid);
          secondValue = parseFloat(secondValue.Market.Bid);
          break;
        case "Allocation":
          firstValue = (((parseFloat(firstValue.Market.Bid) * parseFloat(firstValue.Portfolio.Balance))) / parseFloat(this.state.result.totalewalletwaarde) * 100).toFixed(2);
          secondValue = (((parseFloat(secondValue.Market.Bid) * parseFloat(secondValue.Portfolio.Balance))) / parseFloat(this.state.result.totalewalletwaarde) * 100).toFixed(2);
          break;
        default:
          firstValue = firstValue.Portfolio[this.state.sortField];
          secondValue = secondValue.Portfolio[this.state.sortField];
      }

      if(/(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/gm.test(firstValue)) {
        let parts_a = firstValue.split(/\D/),
            parts_b = secondValue.split(/\D/);
        return new Date(parts_b[0], --parts_b[1], parts_b[2], parts_b[3], parts_b[4], parts_b[5]) - new Date(parts_a[0], --parts_a[1], parts_a[2], parts_a[3], parts_a[4], parts_a[5]);
      } else if(!isNaN(firstValue)) {
        return secondValue - firstValue;
      } else {
        if (secondValue < firstValue) return -1;
        if (secondValue > firstValue) return 1;
        return 0;
      }
    })
    
    return (
        <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive table-hover fs-14 ">
            <div id="example6_wrapper" className="dataTables_wrapper no-footer">
              <table
                className="table display mb-4 dataTablesCard border-no card-table text-black dataTable no-footer"
                id="marketCapital"
                role="grid"
                aria-describedby="example6_info"
              >
                <thead>
                  <tr role="row" className="font-w600">
                    <th style={{cursor: "pointer"}} onClick={() => this.setState({ sortField: "Currency", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} tabIndex={0} rowSpan={1} colSpan={1}>
                      Coin {this.state.sortField === "Currency" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                    </th>
                    <th style={{cursor: "pointer"}} onClick={() => this.setState({ sortField: "AutoSell", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} tabIndex={0} rowSpan={1} colSpan={1}>
                      Auto Sell {this.state.sortField === "AutoSell" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                    </th>
                    <th style={{cursor: "pointer"}} onClick={() => this.setState({ sortField: "AutoBuy", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} tabIndex={0} rowSpan={1} colSpan={1}>
                      Auto Buy {this.state.sortField === "AutoBuy" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                    </th>
                    <th style={{cursor: "pointer"}} onClick={() => this.setState({ sortField: "UsdtBalance", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} tabIndex={0} rowSpan={1} colSpan={1}>
                      <Icon name='usdt' size={16} /> Balance {this.state.sortField === "UsdtBalance" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                    </th>
                    <th style={{cursor: "pointer"}} onClick={() => this.setState({ sortField: "Change24h", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} tabIndex={0} rowSpan={1} colSpan={1}>
                      Change (24h) {this.state.sortField === "Change24h" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                    </th>
                    <th style={{cursor: "pointer"}} onClick={() => this.setState({ sortField: "CurrentPrice", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} tabIndex={0} rowSpan={1} colSpan={1}>
                      Current Price {this.state.sortField === "CurrentPrice" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                    </th>
                    <th style={{cursor: "pointer"}} onClick={() => this.setState({ sortField: "Allocation", sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" })} tabIndex={0} rowSpan={1} colSpan={1}>
                      Allocation {this.state.sortField === "Allocation" ? this.state.sortOrder === "asc" ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' /> : ""}
                    </th>
                  </tr>
                </thead>
                <tbody>
                    {data.map((portfolio, i) => {
                        let oddOrEven = (i % 2 == 0) ? "even" : "odd";

                        let prevDay = (parseFloat(portfolio.Market.Low) + parseFloat(portfolio.Market.High)) / 2;
                        let diff24h = (((parseFloat(portfolio.Market.LastPrice) / prevDay) * 100) - 100).toFixed(2);
                        let allocation = (((parseFloat(portfolio.Market.Bid) * parseFloat(portfolio.Portfolio.Balance))) / parseFloat(this.state.result.totalewalletwaarde) * 100).toFixed(2);

                        let className = (diff24h < 0) ? 'text-danger' : 'text-success';
                        let icon = (diff24h < 0) ? 'fa-caret-down' : 'fa-caret-up';

                        let autoSell = ((parseFloat(portfolio.Market.Bid) / parseFloat(portfolio.Portfolio.autoTradeMinSellPrice) * 100) - 100).toFixed(2);
                        let autoBuy = ((parseFloat(portfolio.Market.Bid) / parseFloat(portfolio.Portfolio.autoTradeMaxBuyPrice) * 100) - 100).toFixed(2);

                        if(!isFinite(autoSell) || isNaN(autoSell)) autoSell = 0;
                        if(!isFinite(autoBuy) || isNaN(autoBuy)) autoBuy = 0;

                        return (
                            <tr role="row" className={oddOrEven}>
                                <td className="wspace-no">
                                  <Link to={`/portfolio#${portfolio.Portfolio.Currency}`}>
                                    <Icon name={portfolio.Portfolio.Currency.toLowerCase()} size={32} />
                                    <span className="text-black ml-3">{portfolio.Portfolio.Currency}</span>
                                  </Link>
                                </td>
                                <td>{autoSell}%</td>
                                <td>{autoBuy}%</td>
                                <td>{this.context.numberFormat((parseFloat(portfolio.Portfolio.Balance) * parseFloat(portfolio.Market.LastPrice)), 2)}</td>
                                <td>
                                    <p className={`mb-0 wspace-no ${className}`}>
                                        <i
                                        className={`fa ${icon} scale5 mr-2`}
                                        aria-hidden="true"
                                        />
                                        {diff24h}%
                                    </p>
                                </td>
                                <td>{this.context.numberFormat(parseFloat(portfolio.Market.Bid), 4)}</td>
                                <td>{allocation}%</td>
                            </tr>
                        )
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Portfolio);